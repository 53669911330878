import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import { addDays, startOfDay, compareAsc, formatISO, parseISO, isDate, intlFormat, set } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { BusBrand, DateType, TripType } from '../../components/transpais/enums'
import { BASE_URL } from '../../utilities/constants'
import {
  ActionFormField,
  Button,
  Loader,
  SegmentedControl,
  Timer,
} from '../../components/transpais'

import { useLogger } from '../../contexts/logger.context'
import { usePinpad } from '../../contexts/pinpad.context'
import { useModalView } from '../../contexts/modalview.context'
import { useModal } from '../../contexts/modal.context'

import {
  bookingSessionOrigin,
  bookingSessionDestination,
  bookingSessionDepartureDate,
  bookingSessionReturningDate,
  bookingSessionTripConfig,
  usuarioID,
  bookingSessionCountFares,
  bookingSessionFares
} from '../../utilities/atoms'

import transpais from '../../services/transpais'

import PlacesView from './partials/PlacesView'
import CalendarView from './partials/CalendarView'

import global from './BookingGlobal.module.scss'
import styles from './BookingSearch.module.scss'
import { use } from 'react'

// Hooks personalizados
const useBookingData = () => {
  const [origins, setOrigins] = useState(null)
  const [destinations, setDestinations] = useState(null)
  const [localLoading, setLocalLoading] = useState(false)
  const [fares, setFares] = useAtom(bookingSessionFares)
  const fareList = ["adulto", "menor", "senectud", "mascota", "preferente", "profesor", "estudiante"]

  const loadOrigins = async () => {
    setLocalLoading(true)
    try {
      const response = await transpais.origins()
      setOrigins(response.data.origins)
    } catch (error) {
      Sentry.captureException(error)
      console.error('Error cargando orígenes:', error)
    } finally {
      setLocalLoading(false)
    }
  }

  const loadFares = async () => {
    try {
      const response = await transpais.fares()
      const faresData = response.data.fares
      const fareDisclaimers = response.data.disclaimer

      let availableFares = faresData
        .filter((fare) => {
          return (
            fareList.includes(fare.indexableName) &&
            !fare.disabled &&
            !fare.deleted
          )
        })
        .map((fare) => {
          return {
            ...fare,
            disclaimer: fareDisclaimers[fare._id] || null,
          }
        })
        .sort((fare1, fare2) => {
          return (fare1.ord || 0) - (fare2.ord || 0)
        })
        //DebugInfo
        availableFares = availableFares.concat(faresData[3], faresData[6], faresData[6])
        console.log('faresData:', faresData)
        console.log('availableFares:', availableFares)

        setFares(availableFares)
    } catch (error) {
      console.error('Error cargando fares:', error)
      Sentry.captureException(error)
    }
  }

  const showDestinations = (bookingOrigin) => {
    if (!bookingOrigin || !origins) return

    setLocalLoading(true)
    try {
      const matchedDestinations = origins.filter((origin) =>
        bookingOrigin.to.includes(origin._id)
      )
      setDestinations(matchedDestinations)
    } catch (error) {
      console.error('Error buscando destinos:', error)
      Sentry.captureException(error)
    } finally {
      setLocalLoading(false)
    }
  }

  return {
    origins,
    destinations,
    localLoading,
    fares,
    setLocalLoading,
    loadOrigins,
    showDestinations,
    loadFares
  }
}

const useBookingDates = () => {
  const [departureDate, setDepartureDate] = useState(null)
  const [returningDate, setReturningDate] = useState(null)
  const [bookingDepartureDate, setBookingDepartureDate] = useAtom(bookingSessionDepartureDate)
  const [bookingReturningDate, setBookingReturningDate] = useAtom(bookingSessionReturningDate)

  const setDates = (date, type) => {
    console.log('Date:', date)
    console.log('Type:', type)
    if (type === DateType.departure) {
      console.log('Setting departure date:', date)
      setDepartureDate(date)
      setBookingDepartureDate(formatISO(date))
    } else if (type === DateType.returning) {
      setReturningDate(date)
      setBookingReturningDate(formatISO(date))
    }
  }

  return {
    departureDate,
    returningDate,
    bookingDepartureDate,
    bookingReturningDate,
    setBookingDepartureDate,
    setBookingReturningDate,
    setDates,
  }
}

// Componente principal
const BookingSearch = () => {
  const navigate = useNavigate()
  const { showModalView, setupModalView, destroyModalView } = useModalView()
  const { showModal, hideModal, setupModal } = useModal()
  const { loggerDebug } = useLogger()
  const { loading } = usePinpad()

  const allowedTypes = [TripType.single, TripType.round]
  const [selectedType, setSelectedType] = useState(TripType.single)
  const [canContinue, setCanContinue] = useState(false)

  const [usuarioId] = useAtom(usuarioID)
  const [bookingOrigin, setBookingOrigin] = useAtom(bookingSessionOrigin)
  const [bookingDestination, setBookingDestination] = useAtom(bookingSessionDestination)
  const [bookingTripConfig, setBookingTripConfig] = useAtom(bookingSessionTripConfig)
  const [count, setCount] = useAtom(bookingSessionCountFares)

  const { origins, destinations, localLoading, fares, setLocalLoading, loadOrigins, showDestinations, loadFares } = useBookingData()
  const { departureDate, returningDate, bookingDepartureDate, bookingReturningDate, setBookingDepartureDate, setBookingReturningDate, setDates } = useBookingDates()
  const [selectedPassengerTypes, setSelectedPassengerTypes] = useState([])
  const allowedPassengerTypes = ['adulto', 'senectud', 'profesor', 'estudiante']

  const tripTypeRef = useRef(null)
  const days = useRef(30)

  // Handlers
  const handleExit = () => {
    navigate(`${BASE_URL}/`)
  }

  const handleChangeTripType = (newType) => {
    setSelectedType(allowedTypes[newType])
  }

  const handleSelectOrigin = (place) => {
    setBookingOrigin(place)
    setBookingDestination(null)
    destroyModalView()
  }

  const handleSelectDestination = (place) => {
    setBookingDestination(place)
    destroyModalView()
  }

  const handleSearch = () => {
    if (canContinue) {
      Sentry.setContext('Search', {
        origen: bookingOrigin,
        destino: bookingDestination,
        tipo: selectedType,
        ida: bookingDepartureDate,
        regreso: bookingReturningDate,
      })
      navigate(`${BASE_URL}/booking/trips`)
    }
  }

  const handleValidateRoundTrip = () => {
    setLocalLoading(true)
    const existsDestination = origins.some((item) => item._id === bookingDestination._id)
    if (!existsDestination) {
      setLocalLoading(false)
      setSelectedType(TripType.single)
      setupModal({
        title: 'Atención',
        content: <p>Lo sentimos, por el momento no contamos con viajes de regreso para tu viaje.</p>,
        actions: {
          positive: {
            title: 'Aceptar',
            onClick: () => {
              handleSearch()
              hideModal()
            },
          },
        },
      })
      showModal()
    } else {
      setLocalLoading(false)
      handleSearch()
    }
  }

  const handleContinueSearch = () => {
    destroyModalView()
    if (!hasSelectedFares) return

    if (bookingTripConfig.type === TripType.round) {
      handleValidateRoundTrip()
    } else {
      handleSearch()
    }
  }

  const handleSelectPassengerTypes = (types) => {
    setSelectedPassengerTypes(types)
  }

  const onIncrement = (fareId) => {
    setCount((prevCount) => {
      const totalCount = Object.values(prevCount).reduce((sum, value) => sum + value, 0)
  
      if (totalCount < 5) {
        return {
          ...prevCount,
          [fareId]: (prevCount[fareId] || 0) + 1,
        }
      }
  
      return prevCount
    })
  }
  
  const onDecrement = (fareId) => {
    setCount((prevCount) => {
      const newCount = { ...prevCount }
      if (newCount[fareId] > 0) {
        newCount[fareId] -= 1
      }
  
      if (newCount[fareId] === 0) {
        delete newCount[fareId]
      }
  
      return newCount
    })
  }

  const getSelectedFares = () => {
    return Object.entries(count)
      .filter(([_, value]) => value > 0)
      .map(([fareId, value]) => ({ fareId, count: value }))
  }

  const hasSelectedFares = fares.some(
    (fare) =>
      allowedPassengerTypes.includes(fare.indexableName) && count[fare._id] > 0
  )

  // Efectos
  useEffect(() => {
    if (bookingDepartureDate === '') {
      setBookingDepartureDate(formatISO(startOfDay(new Date())))
    }
    if (bookingReturningDate === '') {
      setBookingReturningDate(formatISO(startOfDay(addDays(new Date(), 1))))
    }
    loadOrigins()
    loadFares()
  }, [])

  useEffect(() => {
    if (bookingOrigin && bookingDestination && Object.keys(bookingDestination).length > 0) {
      setBookingTripConfig({
        type: selectedType,
        redondo: false,
      })
      if (selectedType === TripType.round) {
        if (compareAsc(parseISO(bookingDepartureDate), parseISO(bookingReturningDate)) <= 0) {
          setCanContinue(true)
        }
      } else {
        setCanContinue(true)
      }
    } else {
      setCanContinue(false)
    }

    console.log('departureDte:', bookingDepartureDate)
  }, [bookingOrigin, bookingDestination, bookingDepartureDate, bookingReturningDate, selectedType])

  useEffect(() => {
    if (destinations) {
      setupModalView({
        title: 'Selecciona un destino',
        content: <PlacesView items={destinations} onChange={handleSelectDestination} />,
      })
      showModalView()
    }
  }, [destinations])

  useEffect(() => {
    if (departureDate) {
      console.log('Departure date selected:', departureDate)
      setBookingDepartureDate(formatISO(departureDate))
    }
    if (returningDate) {
      console.log('Returning date:', returningDate)
      setBookingReturningDate(formatISO(returningDate))
    }
  }, [departureDate])

  return (
    <>
      <Loader show={loading || localLoading} />
      <section className={['section-full', 'section-flex'].join(' ')}>
        <div className={[global.top, styles.top].join(' ')}>
          <div className={['logo', styles.logo].join(' ')}>
            <figure className={['logo-image', 'logo-image-transpais-light'].join(' ')} />
            <Timer variant={'clock'} />
          </div>

          <div className={styles.middle}>
            <div className={styles.saleCard}>
              <SegmentedControl
                items={allowedTypes}
                selected={allowedTypes.findIndex((item) => item === selectedType)}
                onChange={(index) => handleChangeTripType(index)}
              />

              <div className={styles.searchFormFields}>
                <ActionFormField
                  label={'¿De dónde sales?'}
                  value={bookingOrigin?.name ?? 'Selecciona tu origen'}
                  brief={bookingOrigin?.shortcode ?? <FontAwesomeIcon icon={solid('location-dot')} />}
                  onClick={() => {
                    if (!origins) {
                      setLocalLoading(true)
                      loadOrigins()
                    } else {
                      setupModalView({
                        title: 'Selecciona un origen',
                        content: <PlacesView items={origins} onChange={handleSelectOrigin} />,
                      })
                      showModalView()
                    }
                  }}
                />
                <ActionFormField
                  label={'¿A dónde vas?'}
                  value={bookingDestination?.name ?? 'Selecciona tu destino'}
                  brief={bookingDestination?.shortcode ?? <FontAwesomeIcon icon={solid('location-dot')} />}
                  onClick={() => showDestinations(bookingOrigin)}
                />
                <ActionFormField
                  label={'Fecha de salida'}
                  value={
                    bookingDepartureDate &&
                    isDate(parseISO(bookingDepartureDate)) &&
                    intlFormat(parseISO(bookingDepartureDate), {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })
                  }
                  brief={
                    bookingDepartureDate &&
                    isDate(parseISO(bookingDepartureDate)) &&
                    intlFormat(parseISO(bookingDepartureDate), {
                      month: 'short',
                      day: 'numeric',
                    })
                  }
                  onClick={() => {
                    tripTypeRef.current = null
                    setupModalView({
                      title: 'Selecciona tus fechas',
                      content: (
                        <CalendarView
                          count={days.current}
                          onChange={setDates}
                          departure={bookingDepartureDate}
                          returning={bookingReturningDate}
                          tripType={selectedType}
                          type={DateType.departure}
                          onFinish={handleContinueSearch}
                        />
                      ),
                    })
                    showModalView()
                  }}
                />
                <ActionFormField
                  label={'Fecha de regreso'}
                  value={
                    bookingReturningDate &&
                    isDate(parseISO(bookingReturningDate)) &&
                    intlFormat(parseISO(bookingReturningDate), {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })
                  }
                  brief={
                    bookingReturningDate &&
                    isDate(parseISO(bookingReturningDate)) &&
                    intlFormat(parseISO(bookingReturningDate), {
                      month: 'short',
                      day: 'numeric',
                    })
                  }
                  onClick={() => {
                    if (selectedType === TripType.single) {
                      setSelectedType(TripType.round)
                      tripTypeRef.current = TripType.round
                    }

                    setupModalView({
                      title: 'Selecciona tus fechas',
                      content: (
                        <CalendarView
                          count={days.current}
                          onChange={setDates}
                          departure={bookingDepartureDate}
                          returning={bookingReturningDate}
                          tripType={selectedType}
                          type={DateType.returning}
                          onFinish={handleContinueSearch}
                        />
                      ),
                    })
                    showModalView()
                  }}
                  disabled={selectedType !== TripType.round}
                />
              </div>
            </div>
            <div className={ styles.fareCard }>
              <div className={ styles.parentContainer }>
                <h2>¿Quiénes viajan?</h2>
                {fares && fares.length > 0 ? (
                  fares.map((fare) => (
                    <div key={fare._id} className={styles.container}>
                      <button className={styles.minusIcon} onClick={() => onDecrement(fare._id)}></button>
                      <span className={styles.label}>{fare.name}</span>
                      <div className={ styles.counter }>
                        <span className={styles.count}>{count[fare._id] || 0}</span>
                        <button className={styles.plusIcon} onClick={() => onIncrement(fare._id)}></button>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No hay fares disponibles favor de contactar con el administrador</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <footer className={global.bottom}>
          <div className={styles.left}>
            <Button to={'/'} type="neutral" left-icon="chevron-left">
              Volver
            </Button>
          </div>
          <div className={global.middle}>
            <Button onClick={handleExit} type="positive" right-icon="house">
              Ir a inicio
            </Button>
          </div>
          <div className={styles.right}>
            <Button onClick={handleContinueSearch} type="positive" right-icon="chevron-right" disabled={!canContinue || !hasSelectedFares}>
              Buscar
            </Button>
          </div>
        </footer>
      </section>
    </>
  )
}

export default BookingSearch