import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useLogger } from '../../../contexts/logger.context'
import { useKey } from 'react-use'
import { useAtom } from 'jotai'
import {
  bookingSessionDepartureDisponibilidad, bookingSessionDepartureSelectedTickets, bookingSessionTripConfig, bookingSessionDepartureBlockedTickets,
  bookingSessionFares,
  bookingSessionCountFares
} from '../../../utilities/atoms'
import { isEmpty } from 'lodash'

import { useModal } from '../../../contexts/modal.context'

import Keyboard from 'react-simple-keyboard'
// import PlacesViewSection from './PlacesViewSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button } from '../../../components/transpais'

import styles from './PassengerView.module.scss'
import { PassengerType, TicketService, TripAmenity, TripType } from '../../../components/transpais/enums'
import { currency } from '../../../components/transpais/helpers/currency'


const PassengerView = ({seat, disponibilidad, onFinish, departure, selected, listsPassengers, updatedList, noPets, referencia, noPetsWindow, fromoduleReserv}) => {
  // const departures = departure
  const seatInfo = {
    // nombre: null,
    // servicio: [],
    // trip: null,
    // pricing: null,
    // tipo: null,
    // renueve: null,
    // mancuerna: null,
    // redondo: null
  }
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  const keyboard = useRef()
  const [keyboardLayout, setKeyboardLayout] = useState('default')

  const [indexSelected, setIndexSelected] = useState(null)
  
  const [showScreenKeyboard, setShowScreenKeyboard] = useState(false)
  const [name, setName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [selectedPassenger, setSelectedPassenger] = useState('')

  const {loggerDebug} = useLogger()
  const {showModal, hideModal, setupModal} = useModal()

  const [services, setServices] = useState(null)
  // const [selectedDisponibilidad, setSelectedDisponibilidad] = useState(disponibilidad[seat.piso].find((type) => {
  //   return type.categoria === 1
  // }))
  const [selectedServices, setSelectedServices] = useState([])
  // const [departures, setDepartures] = useState(departure)

  // const [bookingDepartureSelectedTickets,] = useAtom(bookingSessionDepartureSelectedTickets)

  const [bookingDepartureSelectedTickets, setBookingDepartureSelectedTickets] = useAtom(bookingSessionDepartureSelectedTickets)
  const [bookingTripConfig, setBookingTripConfig] = useAtom(bookingSessionTripConfig)
  const [bookingDepartureBlockedTickets, setBookingDepartureBlockedTickets] = useAtom(bookingSessionDepartureBlockedTickets)
  const [disabled, setDisabled] = useState(true)
  const [pasajeros, setPasajeros] = useState(listsPassengers)
  const [emptyPassenger, setEmptyPassenger] = useState({})
  const [defaultPrice, setDefaultPrice] = useState(null)
  const [price, setPrice] = useState(null)
  const inputRef = useRef(null)
  const inputLastNameRef = useRef(null);
  const cursorPosition = useRef(0)
  const [cursorPositions, setCursorPositions] = useState({
    name: 0,
    lastName: 0
  });
  const enableCursor = useRef(false)
  const [pasajerosReturn, setPasajerosReturn] = useState(listsPassengers)
  const [fareList,] = useAtom(bookingSessionFares)
  const [countFares,] = useAtom(bookingSessionCountFares)
  const [activeInput, setActiveInput] = useState('name');

  const finish = () => {
    console.log('finish')
    
    if( name !== null ) {
      //Validation for trip type round
      if (bookingTripConfig.type === TripType.round) {
        //While select seats departure
        if (bookingDepartureBlockedTickets.length === 0) {
          
          if (selected.length !== 0) {
            const isSelected = selected.find(({nombre}) => nombre === name)
            if(isSelected === undefined) {
              addSeatInfo()
            } else{
              warning()
            }
          }else{
            addSeatInfo()
          }
        //While select seats round
        } else if ( bookingDepartureBlockedTickets.length !== 0) {

          const result = bookingDepartureBlockedTickets.find(({ pasajero }) => pasajero === name)
          // const isSelected = selected.find(({nombre}) => nombre === name)
          if(result !== undefined && bookingDepartureBlockedTickets.length !== selected.length /*&& isSelected === undefined*/) {
            let _pasajeros = pasajeros
            _pasajeros[selectedPassenger].seatReturn = seat.asiento
            setPasajeros(_pasajeros)
            // console.log(pasajeros)
            addSeatInfo()
          } else{
            // let $message = 'El nombre de los pasajeros de tu viaje de ida deben coincidir con los de tu viaje de regreso.'
            let $message = bookingDepartureBlockedTickets.length !== selected.length ? 'El nombre de los pasajeros de tu viaje de ida deben coincidir con los de tu viaje de regreso.' : 'Has agregado el total de pasajeros permitido segun tu salida'
            
            duplicateReturning($message)
          }
        //
        } else {
          let $message = 'Has agregado el total de pasajeros permitido segun tu salida.'
          duplicateReturning($message)
        }
      //Validation for trip type single
      } else if( bookingTripConfig.type === TripType.single) {

        if (selected.length !== 0) {
          const isSelected = selected.find(({nombre}) => nombre === name)
          if (isSelected === undefined) {
            addSeatInfo()
          }
        }else{
          addSeatInfo()
        }

      }
      onFinish(seatInfo)
      updatedList(pasajeros)
    }
  }

  const duplicateReturning = ($content) => {
    setupModal({
      title: 'Atención',
      content: <p>{$content}</p>,
      actions: {
        positive: {
          title: 'Ok',
          onClick: () => {
            hideModal()
          }
        }
      }
    })
    showModal()
  }

  const warning = ($content) => {
    setupModal({
      title: 'Atención',
      content: <p>Ya seleccionaste un asiento con este nombre de pasajero, ¿estás seguro que deseas continuar?</p>,
      actions: {
        positive: {
          title: 'Continuar',
          onClick: () => {
            addSeatInfo()
            onFinish(seatInfo)
            hideModal()
          }
        },
        negative: {
          title: 'Cancelar',
          onClick: () => {
            hideModal()
          }
        },
      }
    })
    showModal()
  }

  function capitalizeWords(name) {
    return name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  }

  function addSeatInfo() {
    seatInfo.nombre = name
    seatInfo.apellido = lastName
    seatInfo.data = seat
    seatInfo.disponibilidad = []//selectedDisponibilidad
    seatInfo.servicios = selectedServices
    seatInfo.fareId = selectedPassenger
    seatInfo.fare = fareList.find((fare) => fare._id === selectedPassenger)
  }
  const handleClick = () => {
    cursorPosition.current = inputRef.current.selectionStart
    if(cursorPosition.current === name.length) {
      enableCursor.current = false
    } else {
      enableCursor.current = true
    }
  }

  const handleNameClick = () => {
    const pos = inputRef.current.selectionStart;
    setCursorPositions(prev => ({...prev, name: pos}));
  };
  
  const handleLastNameClick = () => {
    const pos = inputLastNameRef.current.selectionStart;
    setCursorPositions(prev => ({...prev, lastName: pos}));
  };

  function onKeyboardKeyPress(button) {
    loggerDebug('Button pressed', button);

    const insertAccentedLetter = (letter, currentValue, setValue) => {
      if (enableCursor.current) {
        setValue(
          currentValue.slice(0, cursorPosition.current) +
            letter +
            currentValue.slice(cursorPosition.current)
        );
        cursorPosition.current = cursorPosition.current + 1;
      } else {
        setValue(currentValue + letter);
      }
    };

    if (button === '{esc}') {
      setShowScreenKeyboard(false);
    } else if (button === '{bksp}') {
      if (activeInput === 'name') {
        if (enableCursor.current) {
          if (cursorPosition.current > 0) {
            setName(name.slice(0, cursorPosition.current - 1) + name.slice(cursorPosition.current));
            cursorPosition.current = cursorPosition.current - 1;
          }
        } else {
          setName(name.slice(0, -1));
        }
      } else {
        if (enableCursor.current) {
          if (cursorPosition.current > 0) {
            setLastName(lastName.slice(0, cursorPosition.current - 1) + lastName.slice(cursorPosition.current));
            cursorPosition.current = cursorPosition.current - 1;
          }
        } else {
          setLastName(lastName.slice(0, -1));
        }
      }
    } else if (button === '{shift}' || button === '{lock}') {
      handleShift();
    } else {
      switch (button) {
        case 'á':
        case 'é':
        case 'í':
        case 'ó':
        case 'ú':
          if (activeInput === 'name') {
            insertAccentedLetter(button, name, setName);
          } else {
            insertAccentedLetter(button, lastName, setLastName);
          }
          break;
        default:
          setCodeKey(button);
      }
    }
  }

  const setCodeKey = (key) => {
    if (key !== 'Enter' && !(key.includes('{') && key.includes('}'))) {
      if (activeInput === 'name') {
        enableCursor.current ? setKeyPosition(key, name, setName) : setName(name + key);
      } else {
        enableCursor.current ? setKeyPosition(key, lastName, setLastName) : setLastName(lastName + key);
      }
    } else if (key === '{at}') {
      if (activeInput === 'name') {
        enableCursor.current ? setKeyPosition('@', name, setName) : setName(name + '@');
      } else {
        enableCursor.current ? setKeyPosition('@', lastName, setLastName) : setLastName(lastName + '@');
      }
    } else if (key === '{space}') {
      if (activeInput === 'name') {
        enableCursor.current ? setKeyPosition(' ', name, setName) : setName(name + ' ');
      } else {
        enableCursor.current ? setKeyPosition(' ', lastName, setLastName) : setLastName(lastName + ' ');
      }
    } else if (key === '{dotcom}') {
      if (activeInput === 'name') {
        enableCursor.current ? setKeyPosition('.com', name, setName) : setName(name + '.com');
      } else {
        enableCursor.current ? setKeyPosition('.com', lastName, setLastName) : setLastName(lastName + '.com');
      }
    }
  }

  function setKeyPosition(key, currentValue, setValue) {
    let newValue = currentValue.substring(0, cursorPosition.current) + key + currentValue.substring(cursorPosition.current);
    let increment = key === '.com' ? 4 : 1;
    cursorPosition.current = cursorPosition.current + increment;
    setValue(newValue);
  }

  function handleShift() {
    loggerDebug(keyboard.current)
    const newLayoutName = keyboardLayout === 'default' ? 'shift' : 'default'
    setKeyboardLayout(newLayoutName)
  }

  const isServiceSelected = (service) => selectedServices.findIndex((_service) => {
    return _service.id === service.id
  })
  const addService = (service) => {
    const index = isServiceSelected(service)
    //console.log(index)
    if (index >= 0) { //existe
      let _currentServices = [...selectedServices]
      _currentServices.splice(index, 1)
      setSelectedServices(_currentServices)
    } else {
      let _currentServices = [...selectedServices]
      _currentServices.push(service)
      setSelectedServices(_currentServices)
    }
  }

  const selectPassenger = (index, ticket) => {
    // departure.length !== 0 ? setIndexSelected(index) : setIndexSelected(null)
    if (ticket.seatReturn === null) {
      // disponibilidad[seat.piso].map((type) => (
      //   type.categoria === ticket.tipo ? setSelectedDisponibilidad(type) : null
      // ))
      setName(ticket.pasajero)
      // setSelectedServices(ticket.servicios)
      setSelectedPassenger(index)
    }
    
  }

  const categoriaPassenger = (index = null, tipo) => {
    let _defaultprice
    let _pasajerosReturn = pasajerosReturn
    disponibilidad[seat.piso].map((type) => {
      if (type.categoria === tipo && isEmpty(_pasajerosReturn[index].category)) {
        _pasajerosReturn[index].category = type.descripcion
        _pasajerosReturn[index].categoryNum = tipo
        setPasajerosReturn(_pasajerosReturn)
        return type.descripcion;
      }
      if( type.categoria === PassengerType.adulto) {
        _defaultprice = type.precio
      }
      // return null
    })
    // _passengerType = _passengerType.filter((val) => val !== null)
    let existCat = disponibilidad[seat.piso].some(passenger => passenger.categoria === tipo);
    
    if (!existCat && isEmpty(emptyPassenger[index])) {
      let _emptyPassenger = emptyPassenger
      _emptyPassenger[index] = PassengerType[tipo]
      setEmptyPassenger(_emptyPassenger)
      setDefaultPrice(_defaultprice)
    }
    // return _passengerType;
  }

  const showPassengers = () => {
    const _passengers = pasajerosReturn.map( (ticket, index) => (
      // ticket.hasSelected !== true ?
      <div onClick={  () =>  selectPassenger(index ,ticket) } className={ [styles.option, selectedPassenger === index ? styles.selected : null, ticket.seatReturn !== null ? styles.blockedSeat : null].join(' ') }>
        { categoriaPassenger(index, ticket.tipo) }
        <div>
          <FontAwesomeIcon icon={ PassengerType.icon(ticket.categoryNum && ticket.categoryNum.length !== null ? ticket.categoryNum : PassengerType.adulto) } className={ styles.icon }/>
        </div>
        <div className={ [ styles.description, /*ticket.categoryNum && ticket.categoryNum.length === null ?*/ styles.defaultPassenger /*: ''*/ ].join(' ') }>
          <h2>
            { ticket.pasajero.length <= 15 
              ? ticket.pasajero 
              : ticket.pasajero.slice(0, 15) + '...' 
            }
          </h2>
          <p>{ !isEmpty(ticket.category) ? ticket.category : PassengerType.adult }</p>
        </div>
        <div className={ styles.cost }>
        <h2> { '$' + pricePassenger(ticket.categoryNum) } </h2>
        </div>
      </div>
    ))

    return _passengers
  }

  const pricePassenger = (tipo) => {
    let _price = null
    let _defaultprice = null
    disponibilidad[seat.piso].map((type) => {
      if (type.categoria === tipo) {
        _price = type.precio
      }
      if( type.categoria === PassengerType.adulto) {
        _defaultprice = type.precio
      }
      return null
    })
    _price = isEmpty(_price) ? parseFloat(_defaultprice).toFixed(2) : parseFloat(_price).toFixed(2)
    return _price
  }
  const _setDisponibilidad = (type) => {
    let _selectedPassenger = pasajerosReturn
    _selectedPassenger[selectedPassenger].category = type.descripcion
    _selectedPassenger[selectedPassenger].categoryNum = type.categoria
    setPasajerosReturn(_selectedPassenger)
    // setSelectedDisponibilidad(type)
    // showDisponibilidad()
    
  }
  const showDisponibilidad = () => {
    // const opciones = disponibilidad[seat.piso].map((type) => {
    //   if ((!seat.ventana && type.categoria !== 6) || (seat.ventana && seat.mascotable) || (seat.ventana && !seat.mascotable && type.categoria !== 6)) {
    //     if ( type.categoria === PassengerType.adulto || type.categoria === PassengerType.estudiante || type.categoria === PassengerType.profesor) {
    //       return (
    //         <div key={`cat_${type.categoria}`} onClick={() => {
    //           _setDisponibilidad(type)
    //           // setSelectedDisponibilidad(type)
    //         }}
    //         className={[styles.option, selectedDisponibilidad.categoria === type.categoria ? styles.selected : null].join(' ')}>
    //           <div>
    //             <FontAwesomeIcon icon={PassengerType.icon(type.categoria)} className={styles.icon} />
    //           </div>
    //           <div className={styles.description}>
    //             <h2>{type.descripcion}</h2>
    //             <p>{PassengerType.note(type.categoria)}</p>
    //           </div>
    //           <div className={styles.cost}>
    //             <h2>{'$' + type.precio + '0'}</h2>
    //           </div>
    //         </div>
    //       );
    //     }
    //   }
    //   return null;
    // });
    const opciones = [];
  
    return opciones;
  }

  const assignedCounts = selected.reduce((acc, passenger) => {
    acc[passenger.fareId] = (acc[passenger.fareId] || 0) + 1;
    return acc;
  }, {});
  
  // Filtrar fares que aún tengan cupos disponibles
  const [filteredFares, setFilteredFares] = useState([]);
  
  useEffect(() => {
    // Función para filtrar la lista estática
    const filterFares = () => {
      // Crear un objeto para contar cuántos pasajeros hay por fareId
      const passengerCount = selected.reduce((acc, passenger) => {
        if (passenger.fareId) {
          acc[passenger.fareId] = (acc[passenger.fareId] || 0) + 1;
        }
        return acc;
      }, {});

      // Filtrar la lista estática
      const filtered = fareList.filter((fare) => {
        // Verificar si el fareId está en la configuración
        if (countFares.hasOwnProperty(fare._id)) {
          // Obtener la cantidad máxima permitida desde la configuración
          const maxQuantity = countFares[fare._id];
          // Obtener la cantidad actual de pasajeros registrados para este fareId
          const currentQuantity = passengerCount[fare._id] || 0;
          // Mostrar solo si aún no se ha alcanzado la cantidad máxima
          return currentQuantity < maxQuantity;
        }
        return false; // Si no está en la configuración, no se muestra
      });
      setFilteredFares(filtered);
    };

    filterFares();
  }, [fareList, countFares, selected]); // Dependencias del useEffect

  useEffect(() => {
    if (departure.length !== 0) {
      pasajeros.map( (ticket, index) => (
        index === selectedPassenger ? selectPassenger(index, ticket) : null
      ))
    }
  }, [selectedPassenger])

  // useEffect(() => {
  // }, [pasajerosReturn])

  // useEffect(() => {
  //   if (selectedDisponibilidad !== null) {
  //     setSelectedServices([])
  //     setServices(selectedDisponibilidad.servicios)
  //   }
  // }, [selectedDisponibilidad])

  useEffect(() => {

    // if( selectedDisponibilidad.categoria !== PassengerType.mascota && !/^((\b[a-zA-Z\u00C0-\u017F.]{2,40}) *){2,}$/i.test(name) ) {
    //     setDisabled(true)
    // } else if ( selectedDisponibilidad.categoria === PassengerType.mascota && !/^((\b[a-zA-Z\u00C0-\u017F]{1,40}\b) *){1,}$/i.test(name) ) {
    //   setDisabled(true)
    // } else {
      // setDisabled(true)
    // }

    if(!isEmpty(name)){
      setName(capitalizeWords(name))
    }
    if(!isEmpty(lastName)){
      setLastName(capitalizeWords(lastName))
    }
    if (!name || !lastName || !selectedPassenger) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

  }, [name, lastName, selectedPassenger])//,selectedDisponibilidad

  useEffect(() =>{ 
    console.log(selectedServices)
  }, [selectedServices])

  useEffect(() => {
    if (filteredFares.length === 1) {
      setSelectedPassenger(filteredFares[0]._id);
    }
  }, [filteredFares]);

  return (
    <div className={ [styles.passengerView, departure.length !== 0 ? styles.passengerRound : null].join(' ') }>
      {/* <div className={ styles.namePassenger }>
        <div>
          <h1 className={styles.seat}>
            <span>Asiento</span>
            {seat.asiento}
          </h1>
        </div>
        <div className={styles.data}>
          <h2>Nombre y apellido</h2>
           <input type="text" placeholder='Nombre completo'/> 
          <input type="text" placeholder="Nombre completo" value={ name }
                 onFocus={ () => setShowScreenKeyboard(true) }
                 onChange={ (e) => setName(e.target.value) }/>
        </div>
      </div>  */}
      { 
      departure.length === 0 ? 
        <div className={ styles.namePassenger }>
        <div>
          <h1 className={styles.seat}>
            <span>Asiento</span>
            {seat?.label}
          </h1>
        </div>
        <div className={styles.data}>
          <h2>Nombre y apellido</h2>
          {/* <input type="text" placeholder='Nombre completo'/> */ }
          <div className={ styles.inputs }>
            <input 
              type="text" 
              placeholder="Nombre" 
              value={name} 
              ref={inputRef} 
              onClick={handleNameClick}
              onFocus={() => {
                setShowScreenKeyboard(true);
                setActiveInput('name');
              }}
              onChange={(e) => setName(e.target.value)}
            />
            <input 
            type="text" 
            placeholder="Apellido" 
            value={lastName}
            ref={inputLastNameRef}
            onClick={handleLastNameClick}
            onFocus={() => {
              setShowScreenKeyboard(true);
              setActiveInput('lastName');
            }}
            onChange={(e) => setLastName(e.target.value)}
          />
          </div>
        </div>
      </div> 
      :
      <>
      <div className={ styles.namePassenger }>
        <div className={ styles.seatRound}>
          <h1 className={[styles.seat, styles.seatPassenger].join(' ')}>
            <span>Asiento</span>
            {seat?.label}
          </h1>
        </div>
      </div>
      <section className={ [styles.optionsSection, !isEmpty(emptyPassenger[selectedPassenger]) ? styles.messagePassenger : ''].join(' ') }>
        <div className={ styles.options }>
          {
            showPassengers()
          }
        </div>
        {
          !isEmpty(emptyPassenger[selectedPassenger]) ? 
            <div className={ styles.messagePassenger }>
              <p>Esta corrida no tiene disponibilidad para "{emptyPassenger[selectedPassenger]}"" por lo que tu boleto será de adulto.</p>
            </div>
          : ''
        }
      </section>
      </>  
      }

      { departure.length === 0 ?
      <>
      <section className={ styles.optionsSection }>
        <h2>Tipo de pasajero</h2>
        <div className={ styles.options }>
          {filteredFares.map((fare) => {
            return (
              <div key={`cat_${fare._id}`} className={styles.optionWrapper}>
                <div
                  onClick={() => setSelectedPassenger(fare._id)}
                  className={[styles.option, selectedPassenger === fare._id ? styles.selected : null].join(' ')}
                >
                  <div>
                    <FontAwesomeIcon icon={PassengerType.icon(fare.indexableName)} className={styles.icon} />
                  </div>
                  <div className={styles.description}>
                    <h2>{fare.name}</h2>
                  </div>
                  <div className={styles.cost}>
                    <h2>{'$' + fare.displayCurrencyValue}</h2>
                  </div>
                </div>
              </div>
            );
          })}
          {/* <div key={ `cat_` } onClick={ () => {
            // setSelectedDisponibilidad(type)
          } }
              className={ [styles.option, null].join(' ') }>
            <div>
              <FontAwesomeIcon icon={ PassengerType.icon(1) } className={ styles.icon }/>
            </div>
            <div className={ styles.description }>
              <h2>{ 'description' }</h2>
              <p>{ PassengerType.note(1) }</p>
            </div>
            <div className={ styles.cost }>
              <h2> { '$' + '45' + '0' } </h2>
            </div>
          </div> */}

          {/* { disponibilidad[seat.piso]
          .filter((type) => {
            if (type.categoria === PassengerType.preferente && seat.destacado === false) {
              return false;
            }
            return true;
          }).map((type) => {
            
            if ((!seat.ventana && type.categoria !== 6) || (seat.ventana && seat.mascotable) || (seat.ventana && !seat.mascotable && type.categoria !== 6)) {
              return (<div key={ `cat_${ type.categoria }` } onClick={ () => {
                    setSelectedDisponibilidad(type)
                  } }
                      className={ [styles.option, selectedDisponibilidad.categoria === type.categoria ? styles.selected : null].join(' ') }>
                    <div>
                      <FontAwesomeIcon icon={ PassengerType.icon(type.categoria) } className={ styles.icon }/>
                    </div>
                    <div className={ styles.description }>
                      <h2>{ type.descripcion }</h2>
                      <p>{ PassengerType.note(type.categoria) }</p>
                    </div>
                    <div className={ styles.cost }>
                      <h2> { '$' + type.precio + '0' } </h2>
                    </div>
                  </div>)
            } 
          }) } */}
        </div>
        {selectedPassenger && (
          <div className={styles.alert}>
           <p>
              {filteredFares
                .find((fare) => fare._id === selectedPassenger)
                ? `${filteredFares.find((fare) => fare._id === selectedPassenger).name}: ${filteredFares.find((fare) => fare._id === selectedPassenger).disclaimer}`
                : ''}
            </p>
          </div>
        )}
      </section>
      </>
      // <section className={ styles.optionsSection }>
      //   <h2>Tipo de pasajero</h2>
      //   <div className={ styles.options }>
      //     { disponibilidad[seat.piso]
      //     .filter((type) => {
      //       if (type.categoria === PassengerType.preferente && seat.destacado === false) {
      //         return false;
      //       }
      //       return true;
      //     }).map((type) => {
            
      //       if ((!seat.ventana && type.categoria !== 6) || (seat.ventana && seat.mascotable) || (seat.ventana && !seat.mascotable && type.categoria !== 6)) {
      //         return (<div key={ `cat_${ type.categoria }` } onClick={ () => {
      //               setSelectedDisponibilidad(type)
      //             } }
      //                 className={ [styles.option,].join(' ') }> 
      //                 //selectedDisponibilidad.categoria === type.categoria ? styles.selected : null
      //               <div>
      //                 <FontAwesomeIcon icon={ PassengerType.icon(type.categoria) } className={ styles.icon }/>
      //               </div>
      //               <div className={ styles.description }>
      //                 <h2>{ type.descripcion }</h2>
      //                 <p>{ PassengerType.note(type.categoria) }</p>
      //               </div>
      //               <div className={ styles.cost }>
      //                 <h2> { '$' + type.precio + '0' } </h2>
      //               </div>
      //             </div>)
      //       } 
      //     }) }
      //   </div>
      // </section>
      :
      isEmpty(emptyPassenger[selectedPassenger]) ? 
      <section className={ styles.optionsSection }>
        <h2>Tipo de pasajero</h2>
        <div className={ styles.options }>
          {/* { showDisponibilidad() } */}
        </div>
      </section>
      : ''
      
      }
      { !fromoduleReserv && <>
        <section className={ styles.optionsSection }>
          {/* <h2>Servicios adicionales</h2>
          <div className={ styles.options }>
            { services?.map((service) => {

              if (service.nombre.toLowerCase().includes('taxi')) {
                return null
              }

              return (
                <div key={ `sv_${ service.id }` } onClick={ () => {
                  addService(service)
                } } className={ [styles.option, isServiceSelected(service) >= 0 ? styles.checked : null].join(' ') }>
                  <div>
                    <FontAwesomeIcon icon={ TicketService.icon(service.nombre) } className={ styles.icon }/>
                  </div>
                  <div className={ styles.description }>
                    <h2>{ service.nombre }</h2>
                    <h3>{ currency(service.precio) }</h3>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={ solid('circle-check') } className={ styles.check }/>
                  </div>
                </div>
              )
            }) }
          </div> */}
        </section>
      </>}

      <footer className={ styles.bottom }>
        <Button disabled={ disabled } type="positive" right-icon="chevron-right" onClick={ finish }>Continuar</Button>
      </footer>

      <div className={ [styles.keyboardWrapper, showScreenKeyboard ? styles.keyboardShown : null].join(' ') }>
        <Keyboard keyboardRef={ r => (keyboard.current = r) }
          //onChange={onKeyboardChange}
                  onKeyPress={ onKeyboardKeyPress }
                  layoutName={ keyboardLayout }
                  layout={ {
                    default: [
                      '{esc} 1 2 3 4 5 6 7 8 9 0 {bksp}',
                      '{blank} q w e r t y u i o p',
                      '{lock} a s d f g h j k l \u00f1 {blank}',
                      '{shift} z x c v b n m á é í ó ú . - {shift}',
                      '{space} {at} {dotcom}',
                    ],
                    shift: [
                      '{esc} 1 2 3 4 5 6 7 8 9 0 {bksp}',
                      'Q W E R T Y U I O P \u0308',
                      '{lock} A S D F G H J K L Ñ {blank}',
                      '{shift} {blank} Z X C V B N M Á É Í Ó Ú _ {shift}',
                      '{space} {at} {dotcom}',
                    ],
                  } }
                  display={ {
                    '{at}': `<svg class="key-icon"><use xlink:Href="#at"/></svg>`,
                    '{bksp}': `<svg class="key-icon"><use xlink:Href="#delete-left"/></svg>`,
                    '{esc}': `<svg class="key-icon"><use xlink:Href="#keyboard-down"/></svg>`,
                    '{blank}': ` `,
                    '{space}': ` `,
                    '{lock}': `<svg class="key-icon"><use xlink:Href="#font-case"/></svg>`,
                    '{shift}': `<svg class="key-icon"><use xlink:Href="#up"/></svg>`,
                    '{dotcom}': `.com`,
                  } }
        />
      </div>
    </div>
  )

}
export default PassengerView