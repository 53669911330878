const disponible = [
    'DP',
    'DPI',
    'SG',
    'SGI',
    'open'
]

const deshabilitado = [
    'VD',
    'RD',
    'NV',
    'CD',
    'PV',
    'occupied'
]

const sugerido = [
    'SG',
    'SGI',
]

const TranspaisSeatStatus = {
    
    disponible: (status) => {
        if ( disponible.includes(status) ) {
            return true
        } else {
            return false
        }
    },
    deshabilitado: (status) => {
        if (deshabilitado.includes(status) ) {
            return true
        } else {
            return false
        }
    },
    sugerido: (status) => {
        if (sugerido.includes(status) ) {
            return true
        } else {
            return false
        }
    }
}
  
export default TranspaisSeatStatus