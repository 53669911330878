import React, { useEffect, useState, useRef } from 'react'
import * as Sentry from '@sentry/react'
import { handleApiError, showErrorAlert, reportError } from '../../helpers/errorHandler'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { formatISO } from 'date-fns'
import { isEmpty } from 'lodash'

import { BASE_URL } from '../../utilities/constants'
import transpais from '../../services/transpais'

import {
  bookingSessionDepartureDisponibilidad,
  bookingSessionDepartureTrip,
  bookingSessionReturningTrip,
  bookingSessionDestination,
  bookingSessionOrigin,
  bookingSessionDepartureBlockedTickets,
  bookingSessionTimeoutDeadline,
  puntoVentaID,
  usuarioID,
  bookingSessionTripConfig,
  bookingSessionReturningBlockedTickets,
  bookingSessionReturningDisponibilidad,
  bookingSessionDepartureTripId,
  bookingSessionReturningTripId,
  TranspaisIDToken,
  bookingSessionTrips,
  bookingSessionCart,
  bookingSessionFares,
  bookingSessionCountFares
} from '../../utilities/atoms'

import {
  Button,
  Timer,
  TripData,
  Acotaciones,
  BusLayout,
  Loader,
} from '../../components/transpais'

import { TripType, PassengerType, SeatStatus } from '../../components/transpais/enums'

import styles from './BookingBus.module.scss'
import global from './BookingGlobal.module.scss'
import { usePinpad } from '../../contexts/pinpad.context'
import { useTimer } from '../../contexts/timer.context'
import { useModal } from '../../contexts/modal.context'
import { id } from 'date-fns/locale'
import {decodeToken} from "../../utilities/token";

function verifyCountFares(countFares, selected) {
  // Crear un objeto para contar los fareId en selected
  const selectedCount = {};

  // Inicializar todos los fareId de countFares en selectedCount con 0
  Object.keys(countFares).forEach(fareId => {
      selectedCount[fareId] = 0;
  });

  // Contar las ocurrencias de cada fareId en selected
  selected.forEach(item => {
      const fareId = item.fareId;
      if (selectedCount.hasOwnProperty(fareId)) {
          selectedCount[fareId]++;
      }
  });

  // Verificar si los conteos coinciden
  for (const fareId in countFares) {
      if (countFares[fareId] !== selectedCount[fareId]) {
          return false; // No coinciden
      }
  }

  return true; // Todos coinciden
}

// Hooks personalizados
const useBusData = () => {
  const [bus, setBus] = useState([])
  const [selected, setSelected] = useState([])
  const [sectionsIds, setSectionsIds] = useState([])
  const [localLoading, setLocalLoading] = useState(false)

  const fetchBusData = async (session, trip, origin, destination, isRoundTrip) => {
    console.log('fetchBusData session_id: ', session)
    setLocalLoading(true)

    try {
      const response = await transpais.bus(session, {
        step: isRoundTrip ? 'return' : 'departure',
        origin: origin._id,
        destination: destination._id,
        arrival: trip.arrival,
        departure: trip.departure,
        duration: trip.duration,
        route: trip.segments[0].routeId,
        schedule: trip.segments[0].name,
        seat_map: trip.segments[0].seatMapId,
        manifest: trip.segments[0].manifestDate,
        leg_from: trip.segments[0].legFrom,
        leg_to: trip.segments[0].legTo,
      })

      setBus(response.data.seatmap.sections)
      setSectionsIds(response.data.seatmap.sections)
      setLocalLoading(false)
      console.log('Bus data:', response.data)
    } catch (error) {
      console.error('Error fetching bus data:', error)
      Sentry.captureException(error)
      setLocalLoading(false)
    }
  }

  return {
    bus,
    selected,
    sectionsIds,
    localLoading,
    fetchBusData,
    setSelected,
    setLocalLoading,
  }
}

// Componente principal
const BookingBus = () => {
  const navigate = useNavigate()
  const { loading } = usePinpad()
  const { hideTimer, setupTimer } = useTimer()
  const { showModal, hideModal, setupModal } = useModal()

  const [usuarioId] = useAtom(usuarioID)
  const [puntoVenta] = useAtom(puntoVentaID)
  const [bookingOrigin] = useAtom(bookingSessionOrigin)
  const [bookingDestination] = useAtom(bookingSessionDestination)
  const [bookingDepartureTrip, setBookingDepartureTrip] = useAtom(bookingSessionDepartureTrip)
  const [bookingReturningTrip, setBookingReturningTrip] = useAtom(bookingSessionReturningTrip)
  const [bookingReturningDisponibilidad, setBookingReturningDisponibilidad] = useAtom(bookingSessionReturningDisponibilidad)
  const [bookingDepartureDisponibilidad, setBookingDepartureDisponibilidad] = useAtom(bookingSessionDepartureDisponibilidad)
  const [bookingDepartureBlockedTickets, setBookingDepartureBlockedTickets] = useAtom(bookingSessionDepartureBlockedTickets)
  const [bookingTimeoutDeadline, setBookingTimeoutDeadline] = useAtom(bookingSessionTimeoutDeadline)
  const [bookingTripConfig] = useAtom(bookingSessionTripConfig)
  const [bookingReturningBlockedTickets, setBookingReturningBlockedTickets] = useAtom(bookingSessionReturningBlockedTickets)
  const [bookingDepartureTripId, setBookingDepartureTripId] = useAtom(bookingSessionDepartureTripId)
  const [bookingReturningTripId] = useAtom(bookingSessionReturningTripId)
  const [bookingCartData, setBookingCartData] = useAtom(bookingSessionCart)
  const [transpaisIdToken] = useAtom(TranspaisIDToken)
  const [session] = useAtom(bookingSessionTrips)
  const [fares] = useAtom(bookingSessionFares)
  const [countFares,] = useAtom(bookingSessionCountFares)
  const {
    bus,
    selected,
    sectionsIds,
    localLoading,
    fetchBusData,
    setSelected,
    setLocalLoading,
  } = useBusData()
  const backReturn = useRef(false)
  const gotoReturning = useRef(false)
  const noPets = useRef([])
  const noPetsWindow = useRef([])
  const isPet = useRef(false)
  const continueBlock = useRef(true)
  const boolSGI = useRef(false)
  const [selectedFloor, setSelectedFloor] = useState(null)
  const [seatSGI, setSeatSGI] = useState(null)
  const [listPassengers, setListPassengers] = useState([])
  const [disabled, setDisabled] = useState(true)

  // Handlers
  const handleExit = () => {
    navigate(`${BASE_URL}/`)
  }

  const handleSelectSeat = (seat) => {
    const index = selected.findIndex((_seat) => 
      _seat.seats.some((seatItem) => seatItem.seatId === seat.data.id)
    );
    let section = seat?.section
    let tokenData = decodeToken(transpaisIdToken)
    const _seat = {
      type: 'departure',
      sectionId: sectionsIds[section]?._id,
      rowLabel: seat.data?.itemRow.toString(),
      seatNumber: seat.data.label.toString(),
      seatId: seat.data.id,
      seatClass: seat.data.seatClassId,
    }

    let _currentSeats = [...selected]
    let newSeat = {
      firstName: seat.nombre,
      lastName: seat.apellido,
      email: seat.email || tokenData.email,
      change: '',
      fareId: seat?.fare?._id,
      fare: seat?.fare?.name,
      fareClassIds: {
        outbound: bookingDepartureTrip.fareClasses[0].id,
        return: bookingDepartureTrip.fareClasses[0].id,
      },
      items: "",
      seats: [_seat],
    }

    if (index >= 0) {
      _currentSeats.splice(index, 1)
    } else {
      _currentSeats.push(newSeat)
    }

    setSelected(_currentSeats)
  }

  const handleCreateCart = () => {
    const cartList = {
      departure_id: bookingDepartureTrip.id,
      return_id: null,
      passengers: selected,
    }
    setLocalLoading(true)
    transpais.cart(session, cartList)
      .then((response) => {
        // setBookingCartData(response.data)
        setBookingDepartureBlockedTickets(response.data)
        setLocalLoading(false)
      })
      .catch((error) => {
        setLocalLoading(false)
        const { userMessage, errorData } = handleApiError(error, {
          selectedSeats: selected,
          tripId: bookingDepartureTrip.id
        })
        showErrorAlert(userMessage, { setupModal, showModal, hideModal })
        // Reportar error
        reportError(error, errorData)
      })
  }

  const handleBack = () => {
    if (backReturn.current) return

    backReturn.current = true

    if (bookingTripConfig.type === TripType.round && bookingTripConfig.redondo) {
      gotoReturning.current = true
      setBookingReturningTrip(null)
    } else {
      setBookingDepartureTripId(null)
      setBookingDepartureTrip(null)
      setBookingReturningTrip(null)
      setBookingDepartureBlockedTickets([])
      setBookingTimeoutDeadline(null)
    }
    navigate(`${BASE_URL}/booking/trips`)
  }

  // Efectos
  useEffect(() => {console.log('selected', selected)}, [selected])
  useEffect(() => {
    if (bookingDepartureTrip && bookingOrigin && bookingDestination) {
      fetchBusData(
        session,
        bookingDepartureTrip,
        bookingOrigin,
        bookingDestination,
        bookingTripConfig.redondo
      )
    }
  }, [bookingDepartureTrip, bookingOrigin, bookingDestination])

  useEffect(() => {
    if (!isEmpty(bookingDepartureBlockedTickets)) {//&& bookingTimeoutDeadline
      console.log('Redirecting to review')
      navigate(`${BASE_URL}/booking/review`)
    } else {
      console.log('No blocked tickets')
    }
  }, [bookingDepartureBlockedTickets, bookingTimeoutDeadline])

  useEffect(() => {
    if (bus.length > 0) {
      setSelectedFloor(bus[0].piso === 2 ? 2 : 1)
    }
  }, [bus])

  useEffect(() => {
    if (verifyCountFares(countFares, selected)) {
      setDisabled(false)
    }
  }, [selected])

  return (
    <>
      <Loader show={loading || localLoading} />
      <section className={['section-full', 'section-flex'].join(' ')}>
        <div className={[global.top, styles.top].join(' ')}>
          <div className={['logo', bookingTripConfig.redondo ? 'roundAlign' : null, styles.logo].join(' ')}>
            <figure className={['logo-image', 'logo-image-transpais-light', bookingTripConfig.redondo ? 'logo-small' : null].join(' ')} />
            {bookingTripConfig.type === TripType.round && (
              <div className={styles.toPill}>
                <div className={styles.pill}>
                  <p>{bookingDepartureBlockedTickets.length === 0 ? 'Selecciona tus asientos de ida' : 'Selecciona tus asientos de regreso'}</p>
                </div>
              </div>
            )}
            <div className={styles.shoppCart}>
              <Timer variant={'clock'} />
            </div>
          </div>

          <div className={styles.middle}>
            <div className={global.content}>
              <div className={styles.tripsHeader}>
                <TripData variant={'brief'} data={{
                  origin: bookingOrigin,
                  destination: bookingDestination,
                  returning: bookingTripConfig.redondo,
                }} />
                <TripData variant={'meta'} meta={{ departure: bookingDepartureTrip, returning: bookingReturningTrip }} />
              </div>

              <hr />

              <div className={styles.departures}>
                <div className={styles.layout}>
                  <div className={styles.acotaciones}>
                    <Acotaciones items={[]} />
                  </div>

                  <div className={styles.bus}>
                    <BusLayout
                      layout={bus}
                      disponibilidad={bookingTripConfig.redondo ? bookingReturningDisponibilidad : bookingDepartureDisponibilidad}
                      selected={selected}
                      onSelect={handleSelectSeat}
                      seatSelect={seatSGI}
                      listPasajeros={setListPassengers}
                      listPassengers={listPassengers}
                      referencia={noPets}
                      noPets={noPets}
                      noPetsWindow={noPetsWindow}
                      selectFloor={setSelectedFloor}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className={global.bottom}>
          <div>
            <Button onClick={handleBack} type="neutral" left-icon="chevron-left">Volver</Button>
          </div>
          <div className={global.middle}></div>
          <div>
            <Button disabled={ disabled } onClick={handleCreateCart} type="positive" right-icon="chevron-right">Continuar</Button>
          </div>
        </footer>
      </section>
    </>
  )
}

export default BookingBus