import React, { useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Link, useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'

import { Button, IconButton, Loader, Timer, InputAutocomplete } from '../../components/transpais'
import Ticket from '../../components/transpais/TranspaisTicket'
import { BASE_URL } from '../../utilities/constants'
import { isEmpty } from 'lodash'
import jwt_decode from "jwt-decode"

import {
  accessToken,
  bookingSessionDepartureBlockedTickets,
  bookingSessionReturningBlockedTickets,
  bookingSessionDepartureTrip,
  bookingSessionDestination,
  bookingSessionOrigin,
  // bookingSessionTripId,
  bookingSessionTickets,
  bookingSessionPayments,
  pinpadPayment,
  bookingSessionTimeoutDeadline,
  usuarioID, bookingSessionReturningTrip, bookingSessionReset,
  bookingSessionTripConfig,
  bookingSessionDepartureTripId,
  bookingSessionReturningTripId,
  reservationMessageConfirm,
  reservationEmail,
  tokenUser,
  TranspaisIDToken
} from '../../utilities/atoms'
import { useModal } from '../../contexts/modal.context'
import { usePinpad } from '../../contexts/pinpad.context'

import '../../scss/Layout.scss'
import global from '../booking/BookingGlobal.module.scss'
import styles from './BookingReviewOrder.module.scss'
import { Helmet } from 'react-helmet'
import { genericErrorMessage } from '../../utilities/alerts'
import { useLogger } from '../../contexts/logger.context'
import { currency } from '../../components/transpais/helpers/currency'
import { format, formatISO, parse, parseISO } from 'date-fns'
import Keyboard from 'react-simple-keyboard'
import transpaisid from '../../services/transpaisid'
import transpais from '../../services/transpais'

import { useTimer } from '../../contexts/timer.context'

const BookingReviewOrder = () => {

  const backReturn = useRef(false)
  const ticketsArea = useRef(null)
  const [localLoading, setLocalLoading] = useState(false)
  const [, setSearch] = useState('')

  const keyboard = useRef()
  const [keyboardLayout, setKeyboardLayout] = useState('default')

  const viajeIda = useRef(null)
  const viajeRegreso = useRef(null)
  const currentUser = useRef(null)
  const timeDone = useRef(false)
  const cursorPosition = useRef(0)
  const enableCursor = useRef(false)
  const [, setShowMails] = useState(false)

  const [showScreenKeyboard, setShowScreenKeyboard] = useState(false)
  const [email, setEmail] = useState('')
  const [isConfirming, setIsConfirming] = useState(false)

  const [showScrollBottom,] = useState(false)
  const [showScrollTop,] = useState(false)

  const [usuario,] = useAtom(usuarioID)
  const [bookingReset,] = useAtom(bookingSessionReset)
  const [bookingOrigin,] = useAtom(bookingSessionOrigin)
  const [bookingDestination,] = useAtom(bookingSessionDestination)
  const [bookingDepartureTrip,] = useAtom(bookingSessionDepartureTrip)
  const [bookingReturningTrip,] = useAtom(bookingSessionReturningTrip)
  // const [bookingTripId, setBookingTripId] = useAtom(bookingSessionTripId)
  const [bookingDepartureBlockedTickets, setBookingDepartureBlockedTickets] = useAtom(bookingSessionDepartureBlockedTickets)
  const [bookingReturningBlockedTickets, setBookingReturningBlockedTickets] = useAtom(bookingSessionReturningBlockedTickets)
  const [bookingTickets, setBookingTickets] = useAtom(bookingSessionTickets)
  const [bookingPayment, setBookingPayment] = useAtom(bookingSessionPayments)
  const [bookingTripConfig,] = useAtom(bookingSessionTripConfig)
  const [bookingDepartureTripId,] = useAtom(bookingSessionDepartureTripId)
  const [bookingReturningTripId,] = useAtom(bookingSessionReturningTripId)
  const [, setReservationMessage] = useAtom(reservationMessageConfirm)
  const [, setReservationEmail] = useAtom(reservationEmail)
  const [transpaisIdToken,] = useAtom(TranspaisIDToken)

  const [, setPayment] = useAtom(pinpadPayment)
  // console.log('BOOKING TRIP ID')
  // console.log(bookingDepartureTripId)
  //DEBUG: copmentar
//   const [charge, setCharge] = useState(null)
  const [canReserve, setCanReserve] = useState(false)
  const navigate = useNavigate()
  const {loading, readCard, charge, onError} = usePinpad()
  const {showModal, hideModal, setupModal} = useModal()
  const {loggerDebug, loggerLog} = useLogger()
  const {showTimer, setupTimer, hideTimer, waitingPayment} = useTimer()
  const [, setTokenUser] = useAtom(tokenUser)
  const [confirmed, setConfirmed] = useState(false)

  const completeMail = [
    'gmail.com',
    'outlook.com',
    'transpais.com.mx',
    'me.com',
    'hotmail.com',
    'icloud.com',
    'yahoo.com.mx',
    'live.com.mx',
    'yahoo.com',
    'hotmail.es',
    'live.com',
    'hotmail.com.mx',
    'prodigy.net.mx',
    'msn.com',
  ]

  const handleClick = (value) => {
    cursorPosition.current = value
    if(cursorPosition.current === email.length) {
      enableCursor.current = false
    } else {
      enableCursor.current = true
    }
  }
  function onKeyboardKeyPress(button) {
    loggerDebug('Button pressed', button)
    if (button === '{esc}') {
      setShowScreenKeyboard(false)
    } else if (button === '{bksp}') {
      if (enableCursor.current) {
        if (cursorPosition.current > 0) {
          setEmail(email.slice(0, cursorPosition.current -1) + email.slice(cursorPosition.current))
          cursorPosition.current = cursorPosition.current - 1
        }
      }else{
        setEmail(email.slice(0, -1))
      }
      // setEmail(email.slice(0, -1))
      // if (showMails === true) {
      //   if (email.slice(-1) === '@') {
      //     setShowMails(false)
      //   } else {
      //     setSearch(search.slice(0, -1))
      //   }
      // }
    } else if (button === '{shift}' || button === '{lock}') {
      handleShift()
    } else {
      setCodeKey(button)
    }

    //show/hidde select mails extensions
    if (button === '{at}') {
      setShowMails(true)
    }
  }

  const setCodeKey = (key) => {
    //if (barcodeInputValue.length >= RESERVATION_CODE_MAX_SIZE) {
    //loggerDebug('exit and not setting new char')
    //return
    //}

    if (key !== 'Enter' && !(key.includes('{') && key.includes('}'))) {
      //if (!isReading) {
      //  setIsReading(true)
      //}
      enableCursor.current ? setKeyPosition(key) : setEmail(email + key)
      // setEmail(email + key)

      if (key === '@') {
        setShowMails(true)
      }

      // if (showMails) {
      //   setSearch(search + key)
      //   console.log('entra search + key->'+search+key)
      // } else {
      //   setSearch('')
      // }

    } else if (key === '{at}' && email.indexOf('@') === -1) {
      enableCursor.current ? setKeyPosition('@') : setEmail(email + '@')
      // setEmail(email + '@')
    } else if (key === '{space}') {
      enableCursor.current ? setKeyPosition(' ') : setEmail(email + ' ')
      // setEmail(email + ' ')
    } else if (key === '{dotcom}') {
      enableCursor.current ? setKeyPosition('.com') : setEmail(email + '.com')
      // setEmail(email + '.com')
    }
  }

  function setKeyPosition (key) {
    if(key !== '@') {
      let addEmail = email.substring(0, cursorPosition.current) + key + email.substring(cursorPosition.current)
      let increment = key === '.com' ? 4 : 1
      cursorPosition.current = cursorPosition.current + increment
      setEmail(addEmail)
    }
  }

  function handleShift() {
    //loggerDebug(keyboard.current)
    const newLayoutName = keyboardLayout === 'default' ? 'shift' : 'default'
    setKeyboardLayout(newLayoutName)
  }

  /*

  const [token,] = useAtom(accessToken)
  const [usuario_id,] = useAtom(usuarioID)
  const [punto_venta_id,] = useAtom(puntoVentaID)
  const [estacion_id,] = useAtom(estacionID)
  const [tickets, setTickets] = useAtom(reservationTickets)
  const [folio, setFolio] = useAtom(reservationFolio)
  const [, setPayment] = useAtom(pinpadPayment)
  
  const [confirmation, setConfirmation] = useAtom(reservationConfirmation)


  const [canContinue, setCanContinue] = useState(false)

  const cancel = () => {
    setupModal({
      title: 'Atención',
      content: <p>¿Estás seguro de cancelar la operación?</p>,
      actions: {
        positive: {
          title: 'Continuar en el pago',
          onClick: () => {
            hideModal()
          }
        },
        negative: {
          title: 'Cancelar operación',
          onClick: () => {
            setTickets([])
            setFolio('')
            navigate(`${ BASE_URL }/reservations/lookup`)
            hideModal()
          }
        },
      }
    })
    showModal()
  }
*/

  const calcularTotalAPagar = (bookingDepartureBlockedTickets, bookingReturningBlockedTickets) => {
    const total = [...bookingDepartureBlockedTickets, ...bookingReturningBlockedTickets].reduce((accumulator, object) => {
      const servicios = object.servicios.reduce((suma, servicio) => {
        return suma + parseFloat(servicio.precio)
      }, 0)

      return accumulator + parseFloat(object.precio) + servicios
    }, 0)

    return currency(total)
  };

  const totalAPagar = calcularTotalAPagar(bookingDepartureBlockedTickets, bookingReturningBlockedTickets)

  const willPay = () => {
    if (!isEmpty(email)) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        setupModal({
          title: 'Atención',
          content: <>
            <p>El correo ingresado no es correcto, por favor verifícalo e inténtalo de nuevo.</p>
          </>,
        })
        showModal()
  
        return
      }
    } else {
      const decodedToken = jwt_decode(transpaisIdToken)
      setEmail(decodedToken.email)
    }
    
    setShowScreenKeyboard(false)

    setupModal({
      title: 'Reserva',
      content: <>
        <p>¿Deseas continuar con la reserva de tus boletos?</p>
      </>,
      actions: {
        positive: {
          title: 'Reservar',
          onClick: () => {
            // hideTimer()
            let _tripId = bookingTripConfig.redondo === true ? bookingReturningTripId : bookingDepartureTripId
            transpais.canReserve(transpaisIdToken, _tripId)
              .then((response) => {
                // currentUser.current = response.data
                console.log('Response can reserve')
                console.log(response)
                if ( response.data.can ) {
                  console.log('Response can reserve')
                  if ( currency(response.data.total) === totalAPagar ) {
                    console.log('total a pagar es igual al total de la reserva')
                    setCanReserve(true)
                  } else {
                    console.log('total a pagar es diferente al total de la reserva')
                    setupModal({
                      title: 'Atención',
                      content: <>
                        <p>El costo con descuento de {totalAPagar} no aplica para la reserva de este viaje.</p>
                      </>,
                      actions: {
                        positive: {
                          title: 'Continuar',
                          onClick: () => {
                            setCanReserve(true)
                            hideModal()
                          }
                        },
                        negative: {
                          title: 'Cancelar',
                          onClick: () => {
                            hideModal()
                          }
                        },
                      }
                    })
                    showModal()
                  }
                } else {
                  console.log('Response can not reserve')
                  setupModal({
                    title: 'Atención',
                    content: <>
                      <p>{response.data.message}</p>
                    </>,
                  })
                  showModal()
                }
                // setCanReserve(true)
                Sentry.setContext('signupGuest', {
                  email: email,
                })
                onError((type, code, message) => {
                  Sentry.setContext('Block', {
                    type: type,
                    code: code,
                    message: message,
                  })
                  Sentry.addBreadcrumb({
                    category: 'ibus',
                    message: 'payment',
                    level: 'error',
                  })
                  let msg = null
                  loggerDebug(type, code, message)
                  switch (code) {
                    case '10':
                      msg = <p>Haz cancelado la operación, puedes intentarlo de nuevo.</p>
                      break
                    case '408':
                      msg = <p>{ message }</p>
                      break
                    default:
                    //msg = <p>Otro: {code}</p>
                  }
                  loggerLog(msg)
                  setupModal(genericErrorMessage(msg, () => {
                    hideModal()
                  }))
                  showModal()
                })
                
              }).catch((error) => {
                const message = error.response.data
                setupModal({
                  title: 'Atención',
                  content: <>
                  <p>{message.message}</p>
                  </>,
                })
                showModal()
              })
            hideModal()
          }
        },
        negative: {
          title: 'No'
        }
      }
    })
    showModal()

    /////

    // transpaisid.signupGuest(email, email)
    //   .then((response) => {
    //     currentUser.current = response.data
    //     setCanReserve(true)
    //     Sentry.setContext('signupGuest', {
    //       email: email
    //     })
    //   }).catch((error) => {
    //     setCanReserve(false)
    //     Sentry.setContext('signupGuest', {
    //       email: email
    //     })
    //     Sentry.addBreadcrumb({
    //       category: 'ibus',
    //       message: 'unblock',
    //       level: 'error',
    //     })
    //     Sentry.captureException(error)
    //     setLocalLoading(false)
    //   })

  }

  const onBack = () => {
    if (backReturn.current === false) {
      console.log('entro function onBack')
      backReturn.current = true
      console.log('setTrue-> ' + backReturn)
      //Desbloquear tickets bookingDepartureBlockedTickets bookingReturningBlockedTickets
      let _bookingTicket = bookingDepartureBlockedTickets
      let returningTicket = false
      if (bookingReturningBlockedTickets.length !== 0) {
        _bookingTicket = bookingReturningBlockedTickets
        returningTicket = true
      }
      setLocalLoading(true)

      let _tripId = bookingTripConfig.redondo === true ? bookingReturningTripId : bookingDepartureTripId

      transpais.unblock(
        transpaisIdToken, _tripId, _bookingTicket.map((ticket) => ticket.id)
      ).then((response) => {
        Sentry.setContext('unblock', {
          tripId: _tripId,
          tickets: _bookingTicket,
        })
        if (returningTicket === true) {
          setBookingReturningBlockedTickets([])
        } else {
          hideTimer()
          setBookingDepartureBlockedTickets([])
          setBookingReturningBlockedTickets([])
        }
        // setBookingReturningBlockedTickets([])
        // setBookingDepartureBlockedTickets([])
      }).catch((error) => {
        Sentry.setContext('unblock', {
          tripId: _tripId,
          tickets: _bookingTicket,
        })
        Sentry.addBreadcrumb({
          category: 'ibus',
          message: 'unblock',
          level: 'error',
        })
        Sentry.captureException(error)
        setLocalLoading(false)

        if (returningTicket === true) {
          setBookingReturningBlockedTickets([])
        } else {
          hideTimer()
          setBookingDepartureBlockedTickets([])
          setBookingReturningBlockedTickets([])
        }
        // setBookingReturningBlockedTickets([])
        // setBookingDepartureBlockedTickets([])
      })

    }
  }

  const addMail = (event, message) => {
    let _email = email.split('@')[0] + '@' + message
    setEmail(_email)
    setShowMails(false)
    setSearch('')
  }

  /*const onTimeout = () => {
    console.log('onTimeout order')
    hideTimer()
    setBookingTimeoutDeadline('')
    setupModal({
      title: 'Tu reserva expiró',
      content: <p>¡Lo sentimos! 😓 No podemos seguir reservando tus asientos porque más personas están intentando
        viajar.
        🚌 Vuelve a iniciar tu compra cuando estés listo.</p>,
      actions: {
        positive: {
          title: 'Aceptar',
          onClick: () => {
            setLocalLoading(true)
            transpais.unblock(
              bookingTripId, bookingDepartureBlockedTickets.map((ticket) => ticket.id)
            ).then((response) => {
              timeDone.current = true
              setLocalLoading(false)
              setBookingReturningBlockedTickets([])
              setBookingDepartureBlockedTickets([])
              hideModal()
            }).catch((error) => {
              timeDone.current = true
              setLocalLoading(false)
              setBookingReturningBlockedTickets([])
              setBookingDepartureBlockedTickets([])
              hideModal()
            })
          }
        }
      }
    })
    showModal()
    console.log('TimeOut' + bookingTimeoutDeadline)
  }*/

  //Unblock Seat When TimeOut

  useEffect(() => {
    if (bookingReturningBlockedTickets.length === 0
      && bookingDepartureBlockedTickets.length === 0
      && timeDone.current === false) {
      console.log('entra navigate effect 1')
      navigate(`${ BASE_URL }/reservationTrips/bus`)
    } else if (bookingReturningBlockedTickets.length === 0 && bookingDepartureBlockedTickets.length !== 0 && bookingTripConfig.redondo === true) {
      console.log('entra navigate effect 2') //--------------------------------
      navigate(`${ BASE_URL }/reservationTrips/bus`)
    } else if (bookingReturningBlockedTickets.length === 0
      && bookingDepartureBlockedTickets.length === 0
      && timeDone.current === true) {
      navigate(`${ BASE_URL }/`)
    }
  }, [bookingReturningBlockedTickets, bookingDepartureBlockedTickets])

  useEffect(() => {
    if (canReserve) {
      console.log('entra canReserve')
      console.log(canReserve)
      console.log( currentUser.current)
      // navigate(`${ BASE_URL }/reservationTrips/confirm`)
      setLocalLoading(true)
      if (!isConfirming) {
        setIsConfirming(true)

        let _tripId = bookingTripConfig.redondo === true ? bookingReturningTripId : bookingDepartureTripId
        let _token = transpaisIdToken
        setTokenUser(_token)
        console.log(_token)
        transpais.booking(_token, bookingDepartureTripId, "taquilla")
          .then((response) => {
            console.log('reserved')
            console.log(response)
            console.log(response.data)
            //data.tickets
            Sentry.setContext('Confirmation', {
              token: transpaisIdToken,
              tripId: _tripId,
              usuario: usuario,
              email: email,
              tickets: bookingDepartureBlockedTickets,
            })
            const tickets = response.data.tickets
            const message = response.data.message
            console.log('response:')
            console.log(response)
            console.log('tickets:')
            console.log(tickets)
            // const payment = response.data.payment
            setConfirmed(true)
            setReservationEmail(email)
            setReservationMessage(message)
            setBookingTickets(tickets)
            // setBookingPayment(payment)
          })
          .catch((error) => {
            Sentry.addBreadcrumb({
              category: 'ibus',
              message: 'confirm',
              level: 'error',
            })
            Sentry.captureMessage(error)
            setLocalLoading(false)
            setupModal({
              title: 'Atención',
              content: <p>No fue posible confirmar tus boletos.</p>,
              actions: {
                positive: {
                  title: 'Aceptar',
                  onClick: () => {
                    //TODO: Error grande
                    timeDone.current = true
                    setBookingReturningBlockedTickets([])
                    setBookingDepartureBlockedTickets([])
                    hideTimer()
                    hideModal()
                  }
                }
              }
            })
            showModal()
          })
      }
    }
  }, [canReserve])
  useEffect(() => {
    if (confirmed) {
      navigate(`${ BASE_URL }/reservationTrips/isconfirm`)
    }
  },[confirmed])
  /*


    const confirmReservation = (token, folio, ticketsToConfirm, total, charge, correo, usuario_id, punto_venta_id, estacion_id, retries = 5) => {
      gateway.reservationConfirm(token, folio, ticketsToConfirm, total, charge, tickets[0].correo, usuario_id, punto_venta_id, estacion_id)
        .then((response) => {
          const data = response.data
          loggerDebug(data)
          if (data.confirmada === false || data.hayError === true) {
            Sentry.setContext('Confirmation', {
              token,
              folio,
              tickets: ticketsToConfirm,
              total,
              charge,
              correo: tickets[0].correo,
              usuario_id,
              punto_venta_id,
              estacion_id,
              data
            })
            Sentry.addBreadcrumb({
              category: "ibus",
              message: "Reintento confirmar",
              level: "error",
            });
            Sentry.captureMessage("Reintento confirmar en respuesta normal.")
            if (retries >= 0) {
              confirmReservation(token, folio, ticketsToConfirm, total, charge, tickets[0].correo, usuario_id, punto_venta_id, estacion_id, retries - 1)
            } else {
              setupModal({
                title: 'Atención',
                content: <p>No fue posible realizar la confirmación de tus boletos.</p>,
              })
              showModal()
              setLocalLoading(false)
              setIsConfirming(false)
            }
            return
          }
          setConfirmation(data)
        })
        .catch((error) => {
          Sentry.setContext('Confirmation', {
            token,
            folio,
            tickets: ticketsToConfirm,
            total,
            charge,
            correo: tickets[0].correo,
            usuario_id,
            punto_venta_id,
            estacion_id,
            error
          })
          Sentry.addBreadcrumb({
            category: "ibus",
            message: "Reintento confirmar",
            level: "error",
          });
          Sentry.captureException(error)
          if (retries >= 0) {
            confirmReservation(token, folio, ticketsToConfirm, total, charge, tickets[0].correo, usuario_id, punto_venta_id, estacion_id, retries - 1)
          } else {
            setupModal({
              title: 'Atención',
              content: <p>No fue posible realizar la confirmación de tus boletos.</p>,
            })
            showModal()
            setLocalLoading(false)
            setIsConfirming(false)
          }
        })
    }


  */
  useEffect(() => {
    //loggerDebug('Proceso boletos')
    /*let fecha = null
    bookingDepartureBlockedTickets.forEach(ticket => {
      console.log(ticket)
      if (fecha == null) {
        fecha = ticket.fecHorViaje
      } else if (fecha !== ticket.fecHorViaje) {
        redondo.current = true
        const viaje1 = moment(fecha, 'DD/MM/YYYY HH:mm')
        const viaje2 = moment(ticket.fecHorViaje, 'DD/MM/YYYY HH:mm')
        if (viaje1.isBefore(viaje2)) {
          viajeIda.current = viaje1
          viajeRegreso.current = viaje2
        } else {
          viajeIda.current = viaje2
          viajeRegreso.current = viaje1
        }
      }
    })
    if (redondo.current === false && fecha != null) {
      viajeIda.current = moment(fecha, 'DD/MM/YYYY HH:mm')
    }*/
    viajeIda.current = bookingDepartureTrip ? parseISO(bookingDepartureTrip.departure.replace(/-05:/, '-06:')) : null
    viajeRegreso.current = bookingReturningTrip ? parseISO(bookingReturningTrip.departure.replace(/-05:/, '-06:')) : null
    console.log('otro', viajeIda.current, bookingReturningTrip, viajeRegreso.current)
    //setupTimer(bookingTimeoutDeadline, onTimeout)
    //showTimer()
  }, [])
  /*
    useEffect(() => {
      const exists = !!tickets.find((ticket) => {
        return ticket.selected === true
      })

      setCanContinue(exists)

      loggerDebug(tickets)
      const empresaId = tickets[0].itemDatosConexion.empresaId
      Sentry.setTag('company', empresaId === 1 ? 'TUN' : empresaId === 552 ? 'TSL' : 'XXX')
      Sentry.setContext('Reservation', {
        folio: folio,
        company: empresaId === 1 ? 'TUN' : empresaId === 552 ? 'TSL' : 'XXX',
        tickets: tickets.map((ticket) => {
          return ticket
        })
      })
    }, [tickets])

    useEffect(() => {
      //loggerDebug(ticketsArea.current.scrollTop)
      //loggerDebug(ticketsArea.current.offsetHeight)
      //loggerDebug(ticketsArea.current.scrollHeight)
      if (ticketsArea.current.scrollHeight > ticketsArea.current.offsetHeight) {
        setShowScrollBottom(true)
      } else {
        setShowScrollBottom(false)
      }
    }, [ticketsArea])



    useEffect(() => {
      //loggerDebug(ticketsArea.current.scrollTop)
      //loggerDebug(ticketsArea.current.offsetHeight)
      //loggerDebug(ticketsArea.current.scrollHeight)
      if (currentTicketsScroll > 0) {
        setShowScrollTop(true)
      } else {
        setShowScrollTop(false)
      }

      if ((ticketsArea.current.scrollTop + ticketsArea.current.offsetHeight) === ticketsArea.current.scrollHeight) {
        setShowScrollBottom(false)
      } else {
        setShowScrollBottom(true)
      }
    }, [currentTicketsScroll])
  */
  useEffect(() => {
    if (bookingTickets != null && bookingTickets.length !== 0
      && bookingPayment != null && bookingPayment.length !== 0) {
      loggerDebug('Confirmation', bookingTickets)
      loggerDebug('set false')
      setLocalLoading(false)
      navigate(`${ BASE_URL }/reservationTrips/confirmed`)
    }
  }, [bookingTickets, bookingPayment])

  useEffect(() => {
    if (bookingReset) {
      navigate(`${ BASE_URL }/`)
    }
  }, [bookingReset])

  // useEffect(() => {
  //   if (search === '') {
  //     setFilteredEmails(completeMail)
  //   } else {
  //     const _items = completeMail.filter(
  //       (el) => el.toLowerCase().includes(search.toLowerCase())
  //     )
  //     setFilteredEmails(_items)
  //   }
  // }, [search])

  const handleScroll = (e) => {
    //etCurrentTicketsScroll(ticketsArea.current.scrollTop)
  }

  return (
    <>
      <Helmet>
      </Helmet>
      {/*----Page----*/ }
      { <Loader show={ loading || localLoading }/> }
      <section className={ ['section-full', 'section-flex'].join(' ') }>
        <div className={ [global.top, styles.top].join(' ') }>
          <div className={ ['logo', styles.logo].join(' ') }>
            <figure className={ ['logo-image', 'logo-image-transpais-light'].join(' ') }/>
            <div className={ styles.timers }>
              <Timer variant={ 'clock' }/>
            </div>
            {/* <div className={ styles.toPay }>
              <FontAwesomeIcon
                icon={ solid('arrow-down') }
                bounce
                className={ styles.pointer }
              />
              <div className={ styles.pill }>
                <p>Selecciona los boletos que quieras pagar</p>
              </div>
            </div> */ }
          </div>
          <div className={ styles.middle }>
            <div className={ styles.details }>
              <div className={ styles.tickets } ref={ ticketsArea } onScroll={ handleScroll }>
                { bookingDepartureBlockedTickets.map((ticket, i) => (
                  <Ticket key={ i } ticket={ ticket }
                              redondo={ bookingReturningBlockedTickets.length > 0 }
                              regreso={ false }
                              tripData={ {origin: bookingOrigin.label, destination: bookingDestination.label} }
                              printable={ false }/>
                )) }
                { bookingReturningBlockedTickets.map((ticket, i) => (
                  <Ticket key={ i } ticket={ ticket }
                              redondo={ true }
                              regreso={ true }
                              tripData={ {origin: bookingDestination.label, destination: bookingOrigin.label} }
                              printable={ false }/>
                )) }
              </div>
              { showScrollTop && <div className={ styles.scrollTop }>
                <IconButton
                  type="neutral"
                  icon="chevron-up"
                  onClick={ () => {
                    ticketsArea.current.scrollBy({top: -300, behavior: 'smooth'})
                    //setCurrentTicketsScroll(ticketsArea.current.scrollTop)
                  } }
                />
              </div> }
              { showScrollBottom && <div className={ styles.scrollBottom }>
                <IconButton
                  type="neutral"
                  icon="chevron-down"
                  onClick={ () => {
                    ticketsArea.current.scrollBy({top: 300, behavior: 'smooth'})
                    //setCurrentTicketsScroll(ticketsArea.current.scrollTop)
                  } }
                />
              </div> }
            </div>
            <div className={ styles.resume }>
              <div className={ styles.card }>
                <div className={ styles.cols }>
                  <div className={ [styles.data, styles.half].join(' ') }>
                    <h2>Origen</h2>
                    <span className={ styles.value }>{ bookingOrigin.label }</span>
                  </div>
                  { bookingReturningBlockedTickets.length > 0 && <div className={ styles.redondo }>
                    <svg className={ [styles.icon].join(' ') }>
                      <use xlinkHref={ `#refresh` }/>
                    </svg>
                  </div> }
                  <div className={ [styles.data, styles.half].join(' ') }>
                    <h2>Destino</h2>
                    <span className={ styles.value }>{ bookingDestination.label }</span>
                  </div>
                </div>
                <div className={ styles.cols }>
                  <div className={ styles.data }>
                    <h2>Fecha y hora de viaje</h2>
                    { <span
                      className={ styles.value }>{ viajeIda.current !== null && format(viajeIda.current, 'dd/MM/yyyy HH:mm') }{ viajeRegreso.current !== null &&
                      ' › ' + format(viajeRegreso.current, 'dd/MM/yyyy HH:mm')
                    }</span> }
                  </div>
                </div>
              </div>
              <div className={ styles.important }>
                <p>Tus boletos serán enviados al correo:</p>
                {/* <input type="text" value={ email } placeholder="Escribe tu correo electrónico"
                       onFocus={ () => setShowScreenKeyboard(true) }
                       onChange={ (e) => {
                         setEmail(e.target.value)
                         console.log('change email')
                         if (e.target.value.includes('@') && !showMails) {
                           setShowMails(true)
                         } else if (!e.target.value.includes('@') && showMails) {
                           setShowMails(false)
                         } else if (e.target.value.includes('@') && showMails) {
                           setSearch(e.target.value.split('@')[1])
                         }
                       } }/> */}
                {/* { showMails ? <div className={ styles.list }>
                  { filteredEmails.map((item) => (
                    <div className={ styles.item } onClick={ event => addMail(event, item) }>
                      <p className={ styles.mail }>{ email.split('@')[0] }<span>@{ item }</span></p>
                    </div>
                  )) }
                </div> : null } */}
                <div>
                {/* <InputAutocomplete items={emails} trigger='@' onChange={(value) => setEmail(value)} /> */}
                  <InputAutocomplete onFocus={ (e) =>  setShowScreenKeyboard(e) } 
                    valueInput={ email } 
                    items={completeMail} 
                    trigger='@' 
                    onChange={(value) => setEmail(value)}
                    handleClicked={handleClick}
                  />
                </div>
                
              </div>
              <div className={ [styles.card, styles.total].join(' ') }>
                <div>
                  <h2>Total a pagar</h2>
                  <span className={ styles.precio }>{totalAPagar}</span>
                  {/* { <span
                    className={ styles.precio }>{ currency([...bookingDepartureBlockedTickets, ...bookingReturningBlockedTickets].reduce((accumulator, object) => {
                    //const precio = object.selected ? object.precioBoleto : 0
                    const servicios = object.servicios.reduce((suma, servicio) => {
                      return suma + parseFloat(servicio.precio)
                    }, 0)

                    return accumulator + parseFloat(object.precio) + servicios
                  }, 0)) }</span> } */}
                  <p>Podrás realizar tu pago utilizando únicamente tu tarjeta de débito o crédito,
                    visa o
                    mastercard.</p>
                  <div className={ styles.cardsLogos }>
                    <img src={ require('../../assets/images/visa.svg').default } alt="VISA"/>
                    <img src={ require('../../assets/images/mastercard.svg').default }
                         alt="mastercard"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className={ global.bottom }>
          <div>
            <Button onClick={ onBack } type="neutral" left-icon="chevron-left">Volver</Button>
            {/* <Button to={ `${ BASE_URL }/booking/bus` } type="neutral" left-icon="chevron-left">Volver</Button> */ }
          </div>
          <div className={ global.middle }>

          </div>
          <div>
            <Button onClick={ willPay } type="positive" right-icon="chevron-right">Validar reservación</Button>
          </div>
        </footer>
      </section>

      <div className={ [styles.keyboardWrapper, showScreenKeyboard ? styles.keyboardShown : null].join(' ') }>
        <Keyboard keyboardRef={ r => (keyboard.current = r) }
          //onChange={onKeyboardChange}
                  onKeyPress={ onKeyboardKeyPress }
                  layoutName={ keyboardLayout }
                  layout={ {
                    default: [
                      '{esc} 1 2 3 4 5 6 7 8 9 0 {bksp}',
                      '{blank} q w e r t y u i o p',
                      '{lock} a s d f g h j k l \u00f1 {blank}',
                      '{shift} z x c v b n m . - {shift}',
                      '{space} {at} {dotcom}',
                    ],
                    shift: [
                      '{esc} 1 2 3 4 5 6 7 8 9 0 {bksp}',
                      'Q W E R T Y U I O P \u0308',
                      '{lock} A S D F G H J K L \u00d1 {blank}',
                      '{shift} {blank} Z X C V B N M _ {shift}',
                      '{space} {at} {dotcom}',
                    ],
                  } }
                  display={ {
                    '{at}': `<svg class="key-icon"><use xlink:Href="#at"/></svg>`,
                    '{bksp}': `<svg class="key-icon"><use xlink:Href="#delete-left"/></svg>`,
                    '{esc}': `<svg class="key-icon"><use xlink:Href="#keyboard-down"/></svg>`,
                    '{blank}': ` `,
                    '{space}': ` `,
                    '{lock}': `<svg class="key-icon"><use xlink:Href="#font-case"/></svg>`,
                    '{shift}': `<svg class="key-icon"><use xlink:Href="#up"/></svg>`,
                    '{dotcom}': `.com`,
                  } }
        />
      </div>
    </>
  )
}

export default BookingReviewOrder
