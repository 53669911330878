import React from 'react'

import styles from './PlacesViewSection.module.scss'

const PlacesViewSection = ({title, items, onClick}) => {
  return (
    <section className={styles.placeView}>
      <h2>{title}</h2>
      <div className={ styles.list }>
        {items.map((item, index) => (
          <div key={`place_${index}`} className={styles.item} onClick={() => {
            onClick(item)
          }}>
            <h3>{item.shortcode}</h3>
            <p>{item.name}</p>
          </div>
        ))}
      </div>
    </section>
  )
}

export default PlacesViewSection