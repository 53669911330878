import axios from 'axios'
import iBus from '../config/ibus'
import { accessToken, TranspaisIDToken } from '../utilities/atoms'

// const urlBase = process.env.REACT_APP_TRANSPAISWS
const urlBase = process.env.REACT_APP_API_TRANSPAIS;
const destinationsUrlBase = process.env.REACT_APP_DESTINATIONS_API; // URL base para Destinations API
const appToken = process.env.REACT_APP_TRANSPAISID_TOKEN
const apikey = process.env.REACT_APP_API_KEY

/**
 * @param {string} url - URL a la cual consultar.
 * Si la URL comienza con "http://" o "https://", se usa directamente.
 * En caso contrario, se concatena con la constante `urlBase`.
 */
const readUrl = (url = '', base = urlBase) =>
  url.startsWith('http://') || url.startsWith('https://') ? url : `${base}/${url}`;

/**
 * Función genérica para solicitudes POST.
 */
const post = (url = '', body = {}, headers = {}, base = urlBase) =>
  axios.post(readUrl(url, base), body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Application: appToken,
      ...headers,
    },
    timeout: 60000,
  });

/**
 * Función genérica para solicitudes GET.
 */
const get = (url = '', headers = {}, base = urlBase) =>
  axios.get(readUrl(url, base), {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Application: appToken,
      ...headers,
    },
    timeout: 60000,
  });


// const origins = (accessToken, user) => post('/v2/booking/from-list', {
//   user,
// }, {
//   Authorization: `Bearer ${ accessToken }`
// })

// const origins = () => {
//   const headers = {
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//     'x-Api-Key': apikey
//   }

//   // axios.get('destinations/api/places');
//   return axios.get('/destinations/api/places', { headers })
//     .then(response => {
//       const filteredDestinations = response.data.filter(place => place.isOrigin);
//       response.data.days = 30
//       response.data.origins = filteredDestinations;
      
//       return response;
//     })
//     .catch(error => {
//       console.error(error);
//       throw error;
//     });
// }

// const destinations = (accessToken, user, origin) => {
//   const body = {
//     origin,
//     user,
//   }

//   return post('/v2/booking/to-list', body, {
//     Authorization: `Bearer ${ accessToken }`
//   })
// }

// const destinations = (listTo) => {
//   const headers = {
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//     'x-Api-Key': apikey
//   }

//   return axios.get('/destinations/api/places', { headers })
//     .then(response => {
//       const filteredDestinations = response.data.filter(place => listTo.includes(place._id));
//       response.data = filteredDestinations;
//       return response;
//     })
//     .catch(error => {
//       console.error(error);
//       throw error; 
//     });
// }

/**
 * Servicios relacionados con Destinations API.
 */
const destinationsApi = {
  /**
   * Obtener la lista de orígenes.
   */
  origins: () => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-Api-Key': apikey,
    };
    return get('api/places', headers, destinationsUrlBase)
      .then((response) => {
        const filteredOrigins = response.data.filter((place) => place.isOrigin);
        return {
          ...response,
          data: {
            ...response.data,
            origins: filteredOrigins,
          },
        };
      })
      .catch((error) => {
        console.error('Error al obtener orígenes:', error);
        throw error;
      });
  },

  /**
   * Obtener la lista de destinos basados en una lista de IDs.
   */
  destinations: (listTo) => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-Api-Key': apikey,
    };

    return get('api/places', headers, destinationsUrlBase)
      .then((response) => {
        const filteredDestinations = response.data.filter((place) => listTo.includes(place._id));
        return {
          ...response,
          data: filteredDestinations,
        };
      })
      .catch((error) => {
        console.error('Error al obtener destinos:', error);
        throw error;
      });
  },
};

// const search = (accessToken, trip_id, origin, destination, redondo, date, userInfo) => {
//   const body = {
//     'platform': {
//       'type': 'Kiosk',
//       'appVersion': `${ process.env.REACT_APP_VERSION } (${ process.env.REACT_APP_BUILD })`,
//       'info': {
//         'platform': userInfo.puntoVentaId,
//         'version': userInfo.usuarioId,
//         'os': userInfo.estacionId,
//         'name': userInfo.cveUsuario,
//         'platformString': userInfo.nombPuntoVenta,
//         'uuid': 1
//       }
//     },
//     user: userInfo.usuarioId,
//     destination,
//     redondo,
//     origin,
//     date
//   }

//   if (trip_id != null && redondo) {
//     body.returning = trip_id
//   }

//   return post('v2/booking/search', body, {
//     Authorization: `Bearer ${ accessToken }`
//   })
// }

// const search = (origin, origin_name, destination, destination_name, departure, return_trip = null, fares) => {
//   const body = {
//     origin,
//     origin_name,
//     destination,
//     destination_name,
//     departure,
//     fares
//   }
//   console.log('return_trip')
//   console.log(return_trip)
//   if (return_trip) {
//     body.return = return_trip
//   }
//   console.log('body Search')
//   console.log(body)
//   // return Promise.resolve(tripsResponse);
//   // SERVICIO FUNCIONAL
//   return axios.post('/api/transpais/v3/booking/trips', body)
//   .then(response => {
//     console.log('trips')
//     console.log(response)
//     return response;
//   })
//   .catch(error => {
//     console.error(error);
//     throw error; 
//   });
// }
const search = (origin, origin_name, destination, destination_name, departure, return_trip = null, fares) => {
  const body = {
    origin,
    origin_name,
    destination,
    destination_name,
    departure,
    fares,
  };
  if (return_trip) {
    body.return = return_trip;
  }
  return post('v3/booking/trips', body); // Elimina el prefijo "/api/transpais"
};

// const bus = (trip_id, key, redondo, punto, usuario) => {
//   const body = {
//     key,
//     redondo,
//     layout: 'horizontal',
//     punto,
//     user: usuario,
//   }

//   return post(`v2/booking/trip/${ trip_id }/bus`, body, {})
// }

// const bus = (session_id, body) => {
//   console.log('body')
//   console.log(body)

//   //SERVICIO FUNCIONAL
//   return axios.post(`/api/transpais/v3/booking/${ session_id }/seats`, body)
//   .then(response => {
//     console.log('seats')
//     console.log(response)
//     return response;
//   })
// }
const bus = (session_id, body) => {
  return post(`v3/booking/${session_id}/seats`, body); // Elimina el prefijo "/api/transpais"
};

// const cart = (session_id, body) => {

//   console.log('cart')
//   console.log('session_id')
//   console.log(session_id)
//   console.log('body')
//   console.log(body)

//   // return Promise.resolve(cartResponse); 
//   return axios.post(`/api/transpais/v3/booking/${ session_id }/cart`, body)
//   .then(response => {
//     console.log('cart')
//     console.log(response)
//     return response;
//   }
//   )
// }
const cart = (session_id, body) => {
  return post(`v3/booking/${session_id}/cart`, body); // Elimina el prefijo "/api/transpais"
};

// const fares = () => {

//   return axios.get(`/api/transpais/v3/booking/fares`)
//   .then(response => {
//     console.log('fares')
//     console.log(response)
//     return response;
//   })
// }
const fares = () => {
  return get('v3/booking/fares');
};

const block = (accessToken, trip_id, seats, usuario, punto, operation = null) => {
  const body = {
    seats,
    usuario,
    punto
  }

  if (operation) {
    body.operation = operation
  }

  return post(`v2/booking/trip/${ trip_id }/bus/seats/block`, body, {
    Authorization: `Bearer ${ accessToken }`
  })
}

const unblock = (accessToken, trip_id, seats, usuario) => {
  const body = {
    seats,
    user: usuario,
  }

  return post(`v2/booking/trip/${ trip_id }/bus/seats/unlock`, body, {
    Authorization: `Bearer ${ accessToken }`
  })
}

const canReserve = (accessToken, trip_id) => get(`v2/booking/trip/${ trip_id }/bus/seats/validate-reservation/taquilla`,{
  Authorization: `Bearer ${ accessToken }`
})

// const canPay = (accessToken, trip_id) => get(`v2/booking/trip/${ trip_id }/bus/seats/can-pay`,{
//   Authorization: `Bearer ${ accessToken }`
// })
// /api/transpais/v3/booking/trips


const canPay = (session_id, token) => {
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return get(`v3/booking/${session_id}/can-pay`, headers)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error('Error en el servicio canPay:', error);
      throw error;
    });
};

// const confirm = (token, trip_id, usuario, card, email, seats) => {
//   const body = {
//     card,
//     email,
//     seats,
//     usuario,
//   }
//   return post(`v2/booking/trip/${ trip_id }/bus/seats/confirm-creditcard`, body, {
//     Authorization: `Bearer ${ token }`
//   })
// }

const confirm = (session_id, body) => {

  // return Promise.resolve({ data: dataResponse })
  //FALTA TESTEAR SERVICIO
  return post(`v3/booking/${session_id}/confirm`, body);
  // return axios.post(`/api/transpais/v3/booking/${ session_id }/confirm`, body)
  // .then(response => {
  //   console.log('confirm')
  //   console.log(response)
  //   return response;
  // })
}

const credentials = (trip_id, usuario, empresa) => get(`v2/payments/mit/${ trip_id }/data/${usuario}/${ empresa }`, {})

const tickets = (reservation, pin) => {
  const body = {
    pin
  }
  return post(`/v2/tools/tickets/by-reservation/${reservation}`, body, {})
}

const booking = (token, trip_id, tipo) => get(`v2/booking/trip/${ trip_id }/bus/seats/validate-reservation/${ tipo }`, {
  Authorization: `Bearer ${ token }`
})

const confirmReservation = (token, trip_id, email, seats) => {
  const body = {
    email,
    seats,
  }
  return post(`v2/booking/trip/${trip_id}/bus/seats/confirm-reservation-cash`, body, {
    Authorization: `Bearer ${ token }`
  })
}

const ticketsAdmin = (accessToken) => get (`/v2/accounts/my-tickets`,{
  Authorization: `Bearer ${ accessToken }`
})

const transpais = {
  // origins,
  // destinations,
  ...destinationsApi,
  search,
  bus,
  block,
  unblock,
  credentials,
  confirm,
  tickets,
  booking,
  confirmReservation,
  canPay,
  canReserve,
  ticketsAdmin,
  cart,
  fares
}

export default transpais
