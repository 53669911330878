import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const TranspaisPassengerType = {
  adulto: 1,
  menor: 2,
  senectud: 3,
  estudiante: 4,
  profesor: 5,
  mascota: 6,
  preferente: 368,
  adult: 'ADULTO',
  younger: 'MENOR',
  senect: 'SENECTUD',
  student: 'ESTUDIANTE',
  teacher: 'PROFESOR',
  pet: 'MASCOTA',
  1: 'ADULTO',
  2: 'MENOR',
  3: 'SENECTUD',
  4: 'ESTUDIANTE',
  5: 'PROFESOR',
  6: 'MASCOTA',
  368: 'PREFERENTE',

  icon: (type) => {
    switch (type) {
      case 1:
      case 'adulto':
        return solid('user-tie')
      case 2: 
      case 'menor':
        return solid('child-reaching')
      case 3: 
      case 'senectud':
        return solid('person-cane')
      case 4:
      case 'estudiante':
        return solid('graduation-cap')
      case 5: return solid('chalkboard-user')
      case 6:
      case 'mascota':
        return solid('paw')
      case true: return solid('wheelchair')
      case 368: return solid('wheelchair')
      default: return null
    }
  },
  note: (type) => {
    switch (type) {
      case 1: return ''
      case 2: return 'De 6 a 11 años'
      case 3: return 'Presentar credencial'
      case 4: return 'Presentar credencial'
      case 5: return 'Presentar credencial'
      case 6: return ''
      case 368: return 'Presentar credencial'
      default: return null
    }
  }
}

export default TranspaisPassengerType