import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { useModalView } from '../../contexts/modalview.context'
import { useModal } from '../../contexts/modal.context'

import {
  bookingSessionDepartureBlockedTickets,
  bookingSessionDepartureSelectedTickets,
  bookingSessionTripConfig,
  bookingSessionDepartureTrip,
  bookingSessionReturningTrip,
  bookingSessionCountFares
} from '../../utilities/atoms'

import {
  BusSeat,
  Loader,
  SegmentedControl,
} from './index'
import { SeatStatus } from './enums'
import PassengerView from '../../modules/booking/partials/PassengerView'

import styles from './TranspaisBusLayout.module.scss'

// Componente principal
const TranspaisBusLayout = ({
  layout,
  disponibilidad,
  selected,
  onSelect,
  seatSelect,
  listPasajeros,
  listPassengers,
  noPets,
  referencia,
  noPetsWindow,
  selectFloor,
  fromReservation,
}) => {
  const [floors, setFloors] = useState([])
  const [selectedFloor, setSelectedFloor] = useState(0)
  const [seatsFloor, setSeatsFloor] = useState([])
  const [selectedSeat, setSelectedSeat] = useState(null)
  const [listaPassengers, setListPassengers] = useState(listPassengers)

  const [bookingDepartureSelectedTickets] = useAtom(bookingSessionDepartureSelectedTickets)
  const [bookingDepartureBlockedTickets] = useAtom(bookingSessionDepartureBlockedTickets)
  const [bookingTripConfig] = useAtom(bookingSessionTripConfig)
  const [bookingDepartureTrip] = useAtom(bookingSessionDepartureTrip)
  const [bookingReturningTrip] = useAtom(bookingSessionReturningTrip)
  const [countFares,] = useAtom(bookingSessionCountFares)

  const { showModalView, setupModalView, destroyModalView } = useModalView()
  const { showModal, hideModal, setupModal } = useModal()

  // Handlers
  const handleChangeBusFloor = (index) => {
    setSelectedFloor(index)
    selectFloor(index + 1)
  }

  const handleSelectSeat = (seat, isSelected) => {
    const totalConf = Object.values(countFares).reduce((acc, value) => acc + value, 0);
    if (isSelected) {
      onSelect({ data: seat })
    // } else if (listPassengers.length !== 0 && selected.length === listPassengers.length && bookingDepartureBlockedTickets.length !== 0) {
    } else if (totalConf === selected.length) {
      setupModal({
        title: 'Atención',
        content: <p>Ya has seleccionado todos los pasajeros. Si deseas cambiar de asiento, haz clic en uno de los que ya escogiste para desbloquearlo y seleccionar otro.</p>,
        actions: {
          positive: {
            title: 'Ok',
            onClick: () => hideModal(),
          },
        },
      })
      showModal()
    } else {
      if (selectedSeat?.label !== seat.label) {
        setSelectedSeat(seat)
      } else {
        handleModalSeatSelected()
      }
    }
  }

  const handleFinishSeatSelection = (seat) => {
    seat.section = selectedFloor
    onSelect(seat)
    destroyModalView()
    setSelectedSeat(null)
  }

  const handleUpdateListPassenger = (listPassenger) => {
    setListPassengers(listPassenger)
    listPasajeros(listPassenger)
  }

  const handleModalSeatSelected = () => {
    setupModalView({
      title: bookingTripConfig.redondo ? 'Elije el pasajero' : 'Pasajero',
      content: (
        <PassengerView
          departure={bookingDepartureBlockedTickets.filter((ticket) => {
            const exists = selected.some((selectedTicket) => (
              selectedTicket.nombre === ticket.pasajero && selectedTicket.tipo === ticket.tipo
            ))
            return !exists
          })}
          seat={selectedSeat}
          disponibilidad={disponibilidad}
          onFinish={handleFinishSeatSelection}
          selected={selected}
          listsPassengers={listaPassengers}
          updatedList={handleUpdateListPassenger}
          noPets={noPets}
          referencia={referencia}
          noPetsWindow={noPetsWindow}
          fromoduleReserv={fromReservation}
        />
      ),
    })
    showModalView()
  }

  // Efectos
  useEffect(() => {
    if (layout.length > 0) {
      setSeatsFloor(layout[selectedFloor].rows)
      const floors = layout.length === 1 ? ['Planta baja'] : ['Planta baja', 'Planta alta']
      layout.forEach((_, index) => {
        layout[index].piso = index
      })
      setFloors(floors)
    }
  }, [layout, selectedFloor])

  useEffect(() => {
    if (selectedSeat !== null) {
      handleModalSeatSelected()
    }
  }, [selectedSeat])

  useEffect(() => {
    if (seatSelect !== null) {
      handleSelectSeat(seatSelect, false)
      handleChangeBusFloor(seatSelect.piso - 1)
    }
  }, [seatSelect])


  const x = (column) => {
    const _column = column - 1

    return (_column * 64) + (_column * 30)
  }

  const y = (row) => {
    const _row = row - 1
    let y = _row * 5

    if (_row > 1) {
      y -= 44 * (_row / 2)

      if (_row > 2) {
        y += 10
      }
    }

    return (_row * 64) + y
  }

  //Version antigua adaptada
  const SeatSelector_ = ({ rows, selected }) => {
    return (
      <>
        {rows?.map((row) =>
          row.seats
            // .sort((a, b) => a.col - b.col) // Ordenar los asientos por la propiedad `col`
            .map((seat) => {
              if (seat?.id && seat.type !== "facilities") {
                // Renderizar el asiento
                return (
                  <BusSeat
                    key={`bs_${seat.id}`}
                    position={{ x: x(seat.row), y: y(seat.col) }}
                    type={seat.type === 'WC' ? 'placeholder' : 'default'}
                    variant={'default'}
                    status={SeatStatus.disponible(seat.type) ? 'available' : 'unavailable'}
                    value={seat.label}
                    selected={selected.some((selectedSeat) => selectedSeat.id === seat.id)}
                    onClick={() => {
                      if (seat.type === "open") {
                        const isSelected = selected.some((selectedSeat) => selectedSeat.id === seat.id)
                        handleSelectSeat(seat, isSelected)
                      }
                    }}
                    categoria={0}
                    destacado={""}
                  />
                )
              }
  
              return null
            })
        )}
      </>
    )
  }
  
  const SeatSelector = ({ rows, selected }) => (
    <div className={styles.rows}>
      {rows?.map((row, index) => (
        <div key={index}>
          <div className={styles.seatsContainer}>
            {row.seats
            .sort((a, b) => a.col - b.col) // Ordenar los asientos por la propiedad `col`
            .map((seat) => {
              if (seat.type === "facilities" && seat.code === "corridor") {
                // Renderizar el corredor
                return <div className={styles.seatGap} key={`corridor-${seat.row}-${seat.col}`}></div>
              }

              if (seat?.id) {
                // Renderizar el asiento
                return (
                  <div
                    key={seat.id}
                    onClick={() => {
                      if (seat.type === "open") {
                        // const isSelected = selected.some((selectedSeat) => selectedSeat.id === seat.id)
                        const isSelected = selected.some((selectedSeat) => 
                          selectedSeat.seats.some((seatItem) => seatItem.seatId === seat.id)
                        );
                        seat.itemRow = row.label
                        handleSelectSeat(seat, isSelected)
                      }
                    }}
                    className={[
                      styles.seat,
                      seat.type !== "open" ? styles.disabled : null,
                      selected.some((selectedSeat) => 
                        selectedSeat.seats.some((seatItem) => seatItem.seatId === seat.id)
                      ) ? styles.selected : null,
                    ].join(" ")}
                  >
                    <span>{seat.label}</span>
                  </div>
                )
              }

              return null
            })}
          </div>
        </div>
      ))}
    </div>
  )

  return (
    <>
      <SegmentedControl items={floors} selected={selectedFloor} onChange={handleChangeBusFloor} />

      <div className={styles.busLayout}>
        {
          selectedFloor === 0 ? (
            <div className={styles.volante}></div>
          ) : null
        }
        <SeatSelector rows={seatsFloor} selected={selected} />
      </div>
    </>
  )
}

export default TranspaisBusLayout