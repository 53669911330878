import React, { useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Link, useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'

import { Button, IconButton, Loader, Timer, InputAutocomplete } from '../../components/transpais'
import Ticket from '../../components/transpais/TranspaisTicket'
import { BASE_URL } from '../../utilities/constants'

import {
  accessToken,
  bookingSessionDepartureBlockedTickets,
  bookingSessionReturningBlockedTickets,
  bookingSessionDepartureTrip,
  bookingSessionDestination,
  bookingSessionOrigin,
  // bookingSessionTripId,
  bookingSessionTickets,
  bookingSessionPayments,
  pinpadPayment,
  bookingSessionTimeoutDeadline,
  usuarioID, bookingSessionReturningTrip, bookingSessionReset,
  bookingSessionTripConfig,
  bookingSessionDepartureTripId,
  bookingSessionReturningTripId,
  reservationMessageConfirm,
  reservationEmail,
  tokenUser,
  reservationConfirmed,
  TranspaisIDToken,
  sessionCanReserve
} from '../../utilities/atoms'
import { useModal } from '../../contexts/modal.context'
import { usePinpad } from '../../contexts/pinpad.context'

import '../../scss/Layout.scss'
import global from '../booking/BookingGlobal.module.scss'
import styles from './BookingIsConfirm.module.scss'
import { Helmet } from 'react-helmet'
import { genericErrorMessage } from '../../utilities/alerts'
import { useLogger } from '../../contexts/logger.context'
import { currency } from '../../components/transpais/helpers/currency'
import { format, formatISO, parse, parseISO } from 'date-fns'
import Keyboard from 'react-simple-keyboard'
import transpaisid from '../../services/transpaisid'
import transpais from '../../services/transpais'

import { useTimer } from '../../contexts/timer.context'

const BookingIsConfirm = () => {

  const backReturn = useRef(false)
  const ticketsArea = useRef(null)
  const [localLoading, setLocalLoading] = useState(false)
  const [, setSearch] = useState('')
  const [, setSessionCanReserve] = useAtom(sessionCanReserve)

  const keyboard = useRef()
  const [keyboardLayout, setKeyboardLayout] = useState('default')

  const viajeIda = useRef(null)
  const viajeRegreso = useRef(null)
  const currentUser = useRef(null)
  const timeDone = useRef(false)
  const cursorPosition = useRef(0)
  const enableCursor = useRef(false)
  const [, setShowMails] = useState(false)

  const [showScreenKeyboard, setShowScreenKeyboard] = useState(false)
  const [email, setEmail] = useState('')
  const [isConfirming, setIsConfirming] = useState(false)

  const [showScrollBottom,] = useState(false)
  const [showScrollTop,] = useState(false)

  const [usuario,] = useAtom(usuarioID)
  const [bookingReset,] = useAtom(bookingSessionReset)
  const [bookingOrigin,] = useAtom(bookingSessionOrigin)
  const [bookingDestination,] = useAtom(bookingSessionDestination)
  const [bookingDepartureTrip,] = useAtom(bookingSessionDepartureTrip)
  const [bookingReturningTrip,] = useAtom(bookingSessionReturningTrip)
  // const [bookingTripId, setBookingTripId] = useAtom(bookingSessionTripId)
  const [bookingDepartureBlockedTickets, setBookingDepartureBlockedTickets] = useAtom(bookingSessionDepartureBlockedTickets)
  const [bookingReturningBlockedTickets, setBookingReturningBlockedTickets] = useAtom(bookingSessionReturningBlockedTickets)
  const [bookingTickets, setBookingTickets] = useAtom(bookingSessionTickets)
  const [bookingPayment, setBookingPayment] = useAtom(bookingSessionPayments)
  const [bookingTripConfig,] = useAtom(bookingSessionTripConfig)
  const [bookingDepartureTripId,] = useAtom(bookingSessionDepartureTripId)
  const [bookingReturningTripId,] = useAtom(bookingSessionReturningTripId)
  const [reservationMessage, setReservationMessage] = useAtom(reservationMessageConfirm)
  const [reservationMail, setReservationEmail] = useAtom(reservationEmail)
  const [, setReservation] = useAtom(reservationConfirmed)
  const [token,] = useAtom(tokenUser)
  const [transpaisIdToken,] = useAtom(TranspaisIDToken)

  const [, setPayment] = useAtom(pinpadPayment)
  console.log('BOOKING TRIP ID')
  console.log(bookingDepartureTripId)
  //DEBUG: copmentar
//   const [charge, setCharge] = useState(null)
  const [canReserve, setCanReserve] = useState(false)
  const navigate = useNavigate()
  const {loading, readCard, charge, onError} = usePinpad()
  const {showModal, hideModal, setupModal} = useModal()
  const {loggerDebug, loggerLog} = useLogger()
  const {showTimer, setupTimer, hideTimer, waitingPayment} = useTimer()
  // const [token,] = useAtom(accessToken)
  const [confirmed, setConfirmed] = useState(false)

  const completeMail = [
    'gmail.com',
    'outlook.com',
    'transpais.com.mx',
    'me.com',
    'hotmail.com',
    'icloud.com',
    'yahoo.com.mx',
    'live.com.mx',
    'yahoo.com',
    'hotmail.es',
    'live.com',
    'hotmail.com.mx',
    'prodigy.net.mx',
    'msn.com',
  ]

  //
  const willReservation = () => {
    
    setShowScreenKeyboard(false)

    setupModal({
      title: '¡Aviso!',
      content: <>
        <p>¿Deseas confirmar tu reserva?</p>
      </>,
      actions: {
        positive: {
          title: 'Reservar',
          onClick: () => {
            // hideTimer()
            let _tripId = bookingTripConfig.redondo === true ? bookingReturningTripId : bookingDepartureTripId
            console.log('booking tickets')
            console.log(bookingTickets)
            //bookingDepartureTripId
            setLocalLoading(true)
            console.log('TranspaisIdToken: '+transpaisIdToken)
            console.log('tripId: '+_tripId)
            console.log('reservationMail: '+reservationMail)
            console.log('bookingTickets:')
            console.log(bookingTickets)
            transpais.confirmReservation(transpaisIdToken, _tripId, reservationMail, bookingDepartureBlockedTickets.map((ticket) => ticket.token))
              .then((response) => {
                console.log('response to confirm reservation')
                console.log(response)
                setReservation(response.data)
                setConfirmed(true)
                onError((type, code, message) => {
                  Sentry.setContext('Block', {
                    type: type,
                    code: code,
                    message: message,
                  })
                  Sentry.addBreadcrumb({
                    category: 'ibus',
                    message: 'reservation',
                    level: 'error',
                  })
                  
                  setupModal(genericErrorMessage(message, () => {
                    hideModal()
                  }))
                  showModal()
                })

              }).catch((error) => {
                Sentry.addBreadcrumb({
                  category: 'ibus',
                  message: 'confirm',
                  level: 'error',
                })
                Sentry.captureMessage(error)
                setLocalLoading(false)
                const message = error.response.data
                setupModal({
                  title: 'Atención',
                  content: <p>{ message.message }</p>,
                  actions: {
                    positive: {
                      title: 'Aceptar',
                      onClick: () => {
                        hideModal()
                      }
                    }
                  }
                })
                showModal()
              })
            hideModal()
            
          }
        },
        negative: {
          title: 'No'
        }
      }
    })
    showModal()


  }

  const onBack = () => {
    if (backReturn.current === false) {
      console.log('entro function onBack')
      backReturn.current = true
      console.log('setTrue-> ' + backReturn)
      //Desbloquear tickets bookingDepartureBlockedTickets bookingReturningBlockedTickets
      let _bookingTicket = bookingDepartureBlockedTickets
      let returningTicket = false
      if (bookingReturningBlockedTickets.length !== 0) {
        _bookingTicket = bookingReturningBlockedTickets
        returningTicket = true
      }
      setLocalLoading(true)

      let _tripId = bookingTripConfig.redondo === true ? bookingReturningTripId : bookingDepartureTripId

      transpais.unblock(
        transpaisIdToken, _tripId, _bookingTicket.map((ticket) => ticket.id)
      ).then((response) => {
        Sentry.setContext('unblock', {
          tripId: _tripId,
          tickets: _bookingTicket,
        })
        if (returningTicket === true) {
          setBookingReturningBlockedTickets([])
          // setSessionCanReserve({})
        } else {
          hideTimer()
          setBookingDepartureBlockedTickets([])
          setBookingReturningBlockedTickets([])
          setSessionCanReserve({})
        }
        // setBookingReturningBlockedTickets([])
        // setBookingDepartureBlockedTickets([])
      }).catch((error) => {
        Sentry.setContext('unblock', {
          tripId: _tripId,
          tickets: _bookingTicket,
        })
        Sentry.addBreadcrumb({
          category: 'ibus',
          message: 'unblock',
          level: 'error',
        })
        Sentry.captureException(error)
        setLocalLoading(false)

        if (returningTicket === true) {
          setBookingReturningBlockedTickets([])
        } else {
          hideTimer()
          setBookingDepartureBlockedTickets([])
          setBookingReturningBlockedTickets([])
        }
        // setBookingReturningBlockedTickets([])
        // setBookingDepartureBlockedTickets([])
      })

    }
  }

  const message = (message) => {
    const startIndex = message.indexOf("antes de ")
    const endIndex = message.indexOf(" (")
    const desiredText = message.substring(startIndex, endIndex)

    const firstLetter = desiredText.charAt(0).toUpperCase()

    return firstLetter + desiredText.slice(1)
  }

  //Unblock Seat When TimeOut

  useEffect(() => {
    if (bookingReturningBlockedTickets.length === 0
      && bookingDepartureBlockedTickets.length === 0
      && timeDone.current === false) {
      console.log('entra navigate effect 1')
      navigate(`${ BASE_URL }/reservationTrips/bus`)
    } else if (bookingReturningBlockedTickets.length === 0 && bookingDepartureBlockedTickets.length !== 0 && bookingTripConfig.redondo === true) {
      console.log('entra navigate effect 2') //--------------------------------
      navigate(`${ BASE_URL }/reservationTrips/bus`)
    } else if (bookingReturningBlockedTickets.length === 0
      && bookingDepartureBlockedTickets.length === 0
      && timeDone.current === true) {
      navigate(`${ BASE_URL }/`)
    }
  }, [bookingReturningBlockedTickets, bookingDepartureBlockedTickets])

  useEffect(() => {
    if (confirmed) {
      navigate(`${ BASE_URL }/reservationTrips/confirm`)
    }
  },[confirmed])

  useEffect(() => {
    viajeIda.current = bookingDepartureTrip ? parseISO(bookingDepartureTrip.departure.replace(/-05:/, '-06:')) : null
    viajeRegreso.current = bookingReturningTrip ? parseISO(bookingReturningTrip.departure.replace(/-05:/, '-06:')) : null
    console.log('otro', viajeIda.current, bookingReturningTrip, viajeRegreso.current)
    //setupTimer(bookingTimeoutDeadline, onTimeout)
    //showTimer()
  }, [])
 
  useEffect(() => {
    if (bookingTickets != null && bookingTickets.length !== 0
      && bookingPayment != null && bookingPayment.length !== 0) {
      loggerDebug('Confirmation', bookingTickets)
      loggerDebug('set false')
      setLocalLoading(false)
      navigate(`${ BASE_URL }/reservationTrips/confirmed`)
    }
  }, [bookingTickets, bookingPayment])

  useEffect(() => {
    if (bookingReset) {
      navigate(`${ BASE_URL }/`)
    }
  }, [bookingReset])

  const handleScroll = (e) => {
    //etCurrentTicketsScroll(ticketsArea.current.scrollTop)
  }

  return (
    <>
      <Helmet>
      </Helmet>
      {/*----Page----*/ }
      { <Loader show={ loading || localLoading }/> }
      <section className={ ['section-full', 'section-flex'].join(' ') }>
        <div className={ [global.top, styles.top].join(' ') }>
          <div className={ ['logo', styles.logo].join(' ') }>
            <figure className={ ['logo-image', 'logo-image-transpais-light'].join(' ') }/>
            <div className={ styles.timers }>
              <Timer variant={ 'clock' }/>
            </div>
          </div>
          <div className={ styles.middle }>
            <div className={ styles.details }>
              <div className={ styles.tickets } ref={ ticketsArea } onScroll={ handleScroll }>
                { bookingDepartureBlockedTickets.map((ticket, i) => (
                  <Ticket key={ i } ticket={ ticket }
                              redondo={ bookingReturningBlockedTickets.length > 0 }
                              regreso={ false }
                              tripData={ {origin: bookingOrigin.label, destination: bookingDestination.label} }
                              printable={ false }/>
                )) }
                { bookingReturningBlockedTickets.map((ticket, i) => (
                  <Ticket key={ i } ticket={ ticket }
                              redondo={ true }
                              regreso={ true }
                              tripData={ {origin: bookingDestination.label, destination: bookingOrigin.label} }
                              printable={ false }/>
                )) }
              </div>
              { showScrollTop && <div className={ styles.scrollTop }>
                <IconButton
                  type="neutral"
                  icon="chevron-up"
                  onClick={ () => {
                    ticketsArea.current.scrollBy({top: -300, behavior: 'smooth'})
                  } }
                />
              </div> }
              { showScrollBottom && <div className={ styles.scrollBottom }>
                <IconButton
                  type="neutral"
                  icon="chevron-down"
                  onClick={ () => {
                    ticketsArea.current.scrollBy({top: 300, behavior: 'smooth'})
                  } }
                />
              </div> }
            </div>
            <div className={ styles.resume }>
              <div className={ styles.card }>
                <div className={ styles.cols }>
                  <div className={ [styles.data, styles.half].join(' ') }>
                    <h2>Origen</h2>
                    <span className={ styles.value }>{ bookingOrigin.label }</span>
                  </div>
                  { bookingReturningBlockedTickets.length > 0 && <div className={ styles.redondo }>
                    <svg className={ [styles.icon].join(' ') }>
                      <use xlinkHref={ `#refresh` }/>
                    </svg>
                  </div> }
                  <div className={ [styles.data, styles.half].join(' ') }>
                    <h2>Destino</h2>
                    <span className={ styles.value }>{ bookingDestination.label }</span>
                  </div>
                </div>
                <div className={ styles.cols }>
                  <div className={ styles.data }>
                    <h2>Fecha y hora de viaje</h2>
                    { <span
                      className={ styles.value }>{ viajeIda.current !== null && format(viajeIda.current, 'dd/MM/yyyy HH:mm') }{ viajeRegreso.current !== null &&
                      ' › ' + format(viajeRegreso.current, 'dd/MM/yyyy HH:mm')
                    }</span> }
                  </div>
                </div>
              </div>
              <div className={ [styles.card, styles.total].join(' ') }>
                <div>
                <p>{reservationMessage}</p>
                  <h2>Total a pagar</h2>
                  { <span
                    className={ styles.precio }>{ currency([...bookingDepartureBlockedTickets, ...bookingReturningBlockedTickets].reduce((accumulator, object) => {
                    //const precio = object.selected ? object.precioBoleto : 0
                    const servicios = object.servicios.reduce((suma, servicio) => {
                      return suma + parseFloat(servicio.precio)
                    }, 0)

                    return accumulator + parseFloat(object.precio) + servicios
                  }, 0)) }</span> }
                  <p>{message(reservationMessage)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className={ global.bottom }>
          <div>
            <Button onClick={ onBack } type="neutral" left-icon="chevron-left">Volver</Button>
            {/* <Button to={ `${ BASE_URL }/booking/bus` } type="neutral" left-icon="chevron-left">Volver</Button> */ }
          </div>
          <div className={ global.middle }>

          </div>
          <div>
            <Button onClick={ willReservation } type="positive" right-icon="chevron-right">Confirmar reservación</Button>
          </div>
        </footer>
      </section>
    </>
  )
}

export default BookingIsConfirm
