import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import { addDays, startOfDay, compareAsc, format, intlFormat, parseISO, isDate, formatISO } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
//import FadingDots from 'react-cssfx-loading/lib/FadingDots'
import { BusBrand, DateType, TripType } from '../../components/transpais/enums'
import { BASE_URL } from '../../utilities/constants'

import {
  ActionFormField,
  Button,
  Loader,
  SegmentedControl,
  Timer,
} from '../../components/transpais'

import { useLogger } from '../../contexts/logger.context'
import { usePinpad } from '../../contexts/pinpad.context'
import { useModalView } from '../../contexts/modalview.context'
import { useModal } from '../../contexts/modal.context'

import {
  bookingSessionOrigin,
  bookingSessionDestination,
  bookingSessionDepartureDate,
  bookingSessionReturningDate,
  bookingSessionTripConfig, usuarioID,
  bookingSessionDepartureTripId,
  bookingSessionReturningTripId,
  TranspaisIDToken,

} from '../../utilities/atoms'

import transpais from '../../services/transpais'

import PlacesView from '../booking/partials/PlacesView'
import CalendarView from '../booking/partials/CalendarView'

import global from '../booking/BookingGlobal.module.scss'
import styles from './BookingSearch.module.scss'

const BookingSearch = () => {

  const testDeparture = {
    brand: BusBrand.ibus,
    originLabel: 'Monterrey',
    destinationLabel: 'Cd. Victoria',
    salida: '7:00',
    disponibles: '44',
    precio: 299.00,
  }

  const navigate = useNavigate()

  const {showModalView, setupModalView, destroyModalView} = useModalView()
  const {showModal, hideModal, setupModal} = useModal()
  const {loggerDebug} = useLogger()
  const {loading} = usePinpad()

  const allowedTypes = [TripType.single, TripType.round]
  const [selectedType, setSelectedType] = useState(TripType.single)
  const [canContinue, setCanContinue] = useState(false)

  const [localLoading, setLocalLoading] = useState(false)
  const [origins, setOrigins] = useState(null)
  const [destinations, setDestinations] = useState(null)
  const [accessToken, setAccessToken] = useAtom(TranspaisIDToken)

  const [usuarioId,] = useAtom(usuarioID)

  const [bookingOrigin, setBookingOrigin] = useAtom(bookingSessionOrigin)
  const [bookingDestination, setBookingDestination] = useAtom(bookingSessionDestination)
  const [bookingDepartureDate, setBookingDepartureDate] = useAtom(bookingSessionDepartureDate)
  const [bookingReturningDate, setBookingReturningDate] = useAtom(bookingSessionReturningDate)
  const [bookingTripConfig, setBookingTripConfig] = useAtom(bookingSessionTripConfig)
  const [bookingDepartureTripId, setBookingDepartureTripId] = useAtom(bookingSessionDepartureTripId)
  const [bookingReturningTripId, setBookingReturningTripId] = useAtom(bookingSessionReturningTripId)
  const [departureDate, setDepartureDate] = useState(bookingDepartureDate)
  const [openNextCalendar, setOpenNextCalendar] = useState(false)
  const [__dateType, setDateType] = useState(DateType.departure)

  const changeTripRound = useRef(null)
  const departureDateRef = useRef()
  const returningDateRef = useRef()
  const days = useRef(30)
  departureDateRef.current = bookingDepartureDate
  returningDateRef.current = bookingReturningDate
  

  const changeTripType = (newType) => {
    setSelectedType(allowedTypes[newType])
  }

  const openOriginsModal = () => {
    setupModalView({
      title: 'Selecciona un origen',
      content: (<PlacesView shortcuts={ null } items={ origins } onChange={ selectOrigin }/>),
    })
    showModalView()
  }

  const loadOrigins = () => {
    console.debug('inicio load origins')
    setLocalLoading(true)
    // accessToken, usuarioId
    transpais.origins().then((response) => {
      Sentry.setContext('Origins', {
        usuarioID: usuarioId,
      })
      setLocalLoading(false)
      const _origins = response.data.origins
      setOrigins(_origins)
      days.current = response.data.days
    }).catch((error) => {
      Sentry.setContext('Origins', {
        usuarioID: usuarioId,
      })
      Sentry.captureException(error)
      setLocalLoading(false)
    })
  }

  const showOrigins = () => {
    loggerDebug('showOrigins', origins)

    if (origins == null) {
      setLocalLoading(true)
      loadOrigins()
    } else {
      openOriginsModal()
    }
  }

  const selectOrigin = (place) => {
    setBookingOrigin(place)
    setBookingDestination(null)
    destroyModalView()
  }

  const showDestinations = () => {
    loggerDebug('showDestinations')
    if (bookingOrigin !== null) {
      setLocalLoading(true)
      let listTo = bookingOrigin.to
      // accessToken, usuarioId, bookingOrigin.id
      transpais.destinations(listTo)
        .then((response) => {
          Sentry.setContext('Destinations', {
            usuarioID: usuarioId,
            originID: bookingOrigin.id,
          })
          setLocalLoading(false)

          const _destinations = response.data.destinations
          setDestinations(_destinations)
        }).catch((error) => {
        Sentry.setContext('Destinations', {
          usuarioID: usuarioId,
          originID: bookingOrigin.id,
        })
        Sentry.captureException(error)
        setLocalLoading(false)
      })
    }
  }

  const selectDestination = (place) => {
    setBookingDestination(place)
    destroyModalView()
  }

  const showCalendar = (type) => {

    let _selectedType = selectedType
    if (changeTripRound.current !== null) {
      _selectedType = changeTripRound.current
    }
    
    loggerDebug('showCalendar', type)
    setupModalView({
      title: 'Selecciona tus fechas',
      content: (
        <CalendarView count={ days.current } onChange={ selectDate } departure={ bookingDepartureDate }
                      returning={ bookingReturningDate }
                      tripType={ _selectedType } type={ type } onFinish={ continueSearch } setDate={ setDate }/>),
    })
    showModalView()
  }

  const search = () => {
    if (canContinue) {
      Sentry.setContext('Search', {
        origen: bookingOrigin,
        destino: bookingDestination,
        tipo: selectedType,
        ida: bookingDepartureDate,
        regreso: bookingReturningDate
      })
      navigate(`${ BASE_URL }/reservationTrips/trips`)
    }
  }

  const validateTripsReturn = () => {
    setLocalLoading(true)
    const existDestination = origins.some(item => item.id === bookingDestination.id);
    if (!existDestination) {
      setLocalLoading(false)
      setSelectedType(TripType.single);
      setupModal({
        title: 'Atención',
        content: <p>Lo sentimos por el momento no contamos con viajes de regreso para tu viaje.</p>,
        actions: {
          positive: {
            title: 'Aceptar',
            onClick: () => {
              search()
              hideModal();
            }
          }
        }
      });
      showModal();
    }
    setLocalLoading(false)
  }

  const continueSearch = () => {
    destroyModalView()
    //Validate if ReturningBooking and exist departures
    if (bookingTripConfig.type === TripType.round) {
     validateTripsReturn()
    } else {
      search()
    } 
  }

  const setDate = (dateDeparture) => {
    setDepartureDate(dateDeparture)
    setDateType(DateType.returning)
    setOpenNextCalendar(true)
  }

  const changeType = (typeDate) => {
    destroyModalView()
    showCalendar(typeDate)
  }

  const selectDate = (date, type) => {
    loggerDebug('select', date, type, selectedType)
    if (type === DateType.departure) {
      setBookingDepartureDate(formatISO(date))
      //changeTripType(allowedTypes.findIndex((item) => item === TripType.single))

      if (selectedType === TripType.single) {
        setBookingReturningDate(formatISO(date))
      }
    } else if (type === DateType.returning) {
      setBookingReturningDate(formatISO(date))
      //changeTripType(allowedTypes.findIndex((item) => item === TripType.round))
    }
  }

  useEffect(() => {
    // Reset booking
    setBookingDepartureDate(formatISO(startOfDay(new Date())))
    setBookingReturningDate(formatISO(startOfDay(addDays(new Date(), 1))))
    loadOrigins()
  }, [])

  useEffect(() => {
    //loggerDebug(bookingDepartureDate, bookingReturningDate)
    if ((bookingOrigin !== null && bookingDestination !== null) && Object.entries(bookingDestination).length !== 0) {
      setBookingTripConfig({
        type: selectedType,
        redondo: false,
      })
      switch (selectedType) {
        case TripType.single:
          setCanContinue(true)
          break
        case TripType.round:
          loggerDebug(bookingDepartureDate, bookingReturningDate)
          if (compareAsc(parseISO(bookingDepartureDate), parseISO(bookingReturningDate)) <= 0) {
            setCanContinue(true)
          }
          break
        default:
          break
      }
    } else {
      setCanContinue(false)
    }
  }, [bookingOrigin, bookingDestination, bookingDepartureDate, bookingReturningDate, selectedType])

  //useEffect(() => {
  //  if (origins !== null) {
  //    openOriginsModal()
  //  }
  //}, [origins])

  useEffect(() => {
    if (destinations !== null) {
      setupModalView({
        title: 'Selecciona un destino',
        content: (<PlacesView shortcuts={ null } items={ destinations } onChange={ selectDestination }/>),
      })
      showModalView()
    }

  }, [destinations])//[listener]

  useEffect(() => {
    if ((__dateType === DateType.returning && openNextCalendar === true) && departureDate.length !== 0) {
      showCalendar(__dateType)
    }
  },[__dateType, openNextCalendar, departureDate])

  return (
    <>
      <Loader show={ loading || localLoading }/>
      <section className={ ['section-full', 'section-flex'].join(' ') }>
        <div className={ [global.top, styles.top].join(' ') }>

          <div className={ ['logo', styles.logo].join(' ') }>
            <figure className={ ['logo-image', 'logo-image-transpais-light'].join(' ') }/>
            <Timer variant={ 'clock' }/>
          </div>

          <div className={ styles.middle }>
            <div className={ styles.saleCard }>

              <SegmentedControl items={ allowedTypes }
                                selected={ allowedTypes.findIndex((item) => item === selectedType) }
                                onChange={ (index) => changeTripType(index) }/>

              <div className={ styles.searchFormFields }>
                <ActionFormField label={ '¿De dónde sales?' } value={ bookingOrigin?.label ?? 'Selecciona tu origen' }
                                 brief={ bookingOrigin?.cve ?? <FontAwesomeIcon icon={ solid('location-dot') }/> } onClick={ showOrigins }/>
                <ActionFormField label={ '¿A dónde vas?' }
                                 value={ bookingDestination?.label ?? 'Selecciona tu destino' }
                                 brief={ bookingDestination?.cve ?? <FontAwesomeIcon icon={ solid('location-dot') }/> } onClick={ showDestinations }/>
                <ActionFormField label={ 'Fecha de salida' }
                                 value={ bookingDepartureDate !== '' && isDate(parseISO(bookingDepartureDate)) && intlFormat(parseISO(bookingDepartureDate), {
                                   year: 'numeric',
                                   month: 'long',
                                   day: 'numeric',
                                 }, {
                                   locale: 'es-MX',
                                 }) }
                                 brief={ bookingDepartureDate !== '' && isDate(parseISO(bookingDepartureDate)) && intlFormat(parseISO(bookingDepartureDate), {
                                   month: 'short',
                                   day: 'numeric',
                                 }, {
                                   locale: 'es-MX',
                                 }) } onClick={ () => {changeTripRound.current = null 
                                 showCalendar(DateType.departure) }}/>
                <ActionFormField label={ 'Fecha de regreso' }
                                 value={ bookingReturningDate !== '' && isDate(parseISO(bookingReturningDate)) && intlFormat(parseISO(bookingReturningDate), {
                                   year: 'numeric',
                                   month: 'long',
                                   day: 'numeric',
                                 }, {
                                   locale: 'es-MX',
                                 }) }
                                 brief={ bookingReturningDate !== '' && isDate(parseISO(bookingReturningDate)) && intlFormat(parseISO(bookingReturningDate), {
                                   month: 'short',
                                   day: 'numeric',
                                 }, {
                                   locale: 'es-MX',
                                 }) } onClick={ () => {
                  
                  if (selectedType === TripType.single) {
                    setSelectedType(TripType.round)
                    changeTripRound.current = TripType.round
                    showCalendar(DateType.returning)
                  } else if (selectedType === TripType.round) {
                    showCalendar(DateType.returning)
                  }
                } } disabled={ selectedType !== TripType.round }/>
              </div>
            </div>

            <div className={ [styles.saleCard, styles.cardDepartures].join(' ') }>
              {/*<div className={ styles.spinnerLoad }>*/ }
              {/*<FadingDots color="#003366"*/ }
              {/*            width={ '74px' }*/ }
              {/*            height={ '74px' }*/ }
              {/*/>*/ }
              {/* <FontAwesomeIcon
                                            icon={solid('spinner')}
                                            pulse
                                        /> */ }
              {/* <svg>
<use xlinkHref='#spinner'/>
                            </svg> */ }
              {/*<p>Buscando...</p>*/ }
              {/*</div>*/ }

              {/*<h2>Próximas salidas</h2>*/ }

              {/*<div className={ styles.departures }>*/ }

              {/*  <Departure variant={ DepartureViewVariant.quick } data={ testDeparture }/>*/ }
              {/*  <Departure variant={ DepartureViewVariant.quick } data={ testDeparture }/>*/ }
              {/*  <Departure variant={ DepartureViewVariant.quick } data={ testDeparture }/>*/ }

              {/*</div>*/ }
            </div>
          </div>
          {/* Modal form data passegner*/ }
          {/* <Origin/> */ }
        </div>

        <footer className={ global.bottom }>
          <div className={ styles.left }>
            <Button to={ '/' } type="neutral" left-icon="chevron-left">Volver</Button>
          </div>
          <div className={ styles.right }>
            <Button onClick={ continueSearch } type="positive" right-icon="chevron-right"
                    disabled={ !canContinue }>Buscar</Button>
            {/* <Button onClick={ searchTrips } type="positive" right-icon="chevron-right"
                  disabled={ !canContinue }>Buscar</Button> */ }
          </div>
        </footer>
      </section>

    </>)

}

export default BookingSearch