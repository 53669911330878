import React, { useEffect, useState, useRef } from 'react'
import * as Sentry from '@sentry/react'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { format, parseISO } from 'date-fns'

import transpais from '../../services/transpais'
import { useModalView } from '../../contexts/modalview.context'
import { useLogger } from '../../contexts/logger.context'
import { usePinpad } from '../../contexts/pinpad.context'
import { useTimer } from '../../contexts/timer.context'
import { TripType } from '../../components/transpais/enums'
import { BASE_URL } from '../../utilities/constants'

import {
  claveUsuario,
  usuarioID,
  puntoVentaID,
  estacionID,
  accessToken,
  nombrePuntoVenta,
  bookingSessionOrigin,
  bookingSessionDestination,
  bookingSessionDepartureTrip,
  bookingSessionDepartureDisponibilidad,
  bookingSessionDepartureSelectedTickets,
  bookingSessionDepartureBlockedTickets,
  bookingSessionDepartureDate,
  bookingSessionReturningDate,
  bookingSessionTripConfig,
  bookingSessionReturningTrip,
  bookingSessionReturningBlockedTickets,
  bookingSessionDepartureTripId,
  bookingSessionReturningTripId,
  usuarioEmpresa,
  TranspaisIDToken,
  bookingSessionTrips,
  bookingSessionCountFares,
  bookingSessionFares
} from '../../utilities/atoms'

import styles from './BookingTrips.module.scss'
import global from './BookingGlobal.module.scss'
import {
  Button, Carrousel, Departure, Timer, TripData, Loader,
} from '../../components/transpais'
import { DepartureViewVariant } from '../../components/transpais/enums'
import { use } from 'react'

// Hooks personalizados
const useTripData = () => {
  const [trips, setTrips] = useState([])
  const [tripPages, setTripPages] = useState([])
  const [localLoading, setLocalLoading] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [session_id, setSessionId] = useAtom(bookingSessionTrips)

  const searchForTrips = async (origin, destination, departureDate, returnDate, isRoundTrip, bookingFares) => {
    setLocalLoading(true)
    setNotFound(false)

    try {
      const formattedDepartureDate = format(parseISO(departureDate), 'yyyy-MM-dd') // Formato correcto
      let formattedReturnDate = ''
      if (isRoundTrip) {
        formattedReturnDate = returnDate ? format(parseISO(returnDate), 'yyyy-MM-dd') : null // Formato correcto
      }
      const response = await transpais.search(
        origin._id,
        origin.name,
        destination._id,
        destination.name,
        formattedDepartureDate,
        formattedReturnDate,
        bookingFares,
      )
      
      setSessionId(response.data.session_id)
      const departures = response.data.trips.departures
      setTrips(departures)

      if (departures.length === 0) {
        setNotFound(true)
      } else {
        setNotFound(false)
      }
    } catch (error) {
      console.error('Error buscando viajes:', error)
      Sentry.captureException(error)
      setNotFound(true)
    } finally {
      setLocalLoading(false)
    }
  }

  const organizeTripsIntoPages = (trips, itemsPerPage = 6) => {
    const pages = []
    for (let i = 0; i < trips.length; i += itemsPerPage) {
      pages.push(trips.slice(i, i + itemsPerPage))
    }
    setTripPages(pages)
  }

  return {
    trips,
    tripPages,
    localLoading,
    setLocalLoading,
    notFound,
    searchForTrips,
    organizeTripsIntoPages,
  }
}

// Componente principal
const BookingTrips = () => {
  const navigate = useNavigate()
  const { loggerDebug } = useLogger()
  const { loading, changeCompany } = usePinpad()
  const { hideTimer } = useTimer()

  const [bookingOrigin] = useAtom(bookingSessionOrigin)
  const [bookingDestination] = useAtom(bookingSessionDestination)
  const [bookingDepartureDate] = useAtom(bookingSessionDepartureDate)
  const [bookingReturningDate] = useAtom(bookingSessionReturningDate)
  const [bookingTripConfig, setBookingTripConfig] = useAtom(bookingSessionTripConfig)
  const [bookingDepartureTrip, setBookingDepartureTrip] = useAtom(bookingSessionDepartureTrip)
  const [bookingReturningTrip, setBookingReturningTrip] = useAtom(bookingSessionReturningTrip)
  const [bookingDepartureTripId, setBookingDepartureTripId] = useAtom(bookingSessionDepartureTripId)
  const [bookingReturningTripId, setBookingReturningTripId] = useAtom(bookingSessionReturningTripId)
  const [bookingDepartureBlockedTickets, setBookingDepartureBlockedTickets] = useAtom(bookingSessionDepartureBlockedTickets)
  const [bookingReturningBlockedTickets, setBookingReturningBlockedTickets] = useAtom(bookingSessionReturningBlockedTickets)
  const [bookingFares] = useAtom(bookingSessionCountFares)
  const [transpaisIdToken] = useAtom(TranspaisIDToken)
  const [toUpdateFareList, setToUpdateFareList] = useAtom(bookingSessionFares)

  const {
    trips,
    tripPages,
    localLoading,
    setLocalLoading,
    notFound,
    searchForTrips,
    organizeTripsIntoPages,
  } = useTripData()

  const backReturn = useRef(false)

  // Handlers
  const handleExit = () => {
    navigate(`${BASE_URL}/`)
  }

  const handleSelectTrip = (trip) => {
    loggerDebug('Seleccionando viaje:', trip)
    setLocalLoading(true)

    if (bookingTripConfig.redondo) {
      setBookingReturningTrip(trip)
    } else {
      setBookingDepartureTrip(trip)
    }

    //Agregar price a faresList
    let faresTrip = trip.fareClasses[0].fares
    setToUpdateFareList((prevState) => {
      return prevState.map((item) => {
        const match = faresTrip.find((fare) => fare.id === item._id);
    
        if (match) {
          return {
            ...item,
            displayCurrencyValue: match.displayCurrencyValueToDisplay
          };
        }
    
        return item;
      });
    });

    setLocalLoading(false)
  }

  const handleBack = () => {
    if (backReturn.current) return

    backReturn.current = true
    console.log('Regresando a búsqueda de viajes...')
    if (bookingTripConfig.redondo) {
      setBookingReturningTrip(null)
      setBookingTripConfig({ ...bookingTripConfig, redondo: false })
      setLocalLoading(true)

      transpais.unblock(
        transpaisIdToken,
        bookingDepartureTripId,
        bookingDepartureBlockedTickets.map((ticket) => ticket.id)
      ).then(() => {
        setBookingDepartureBlockedTickets([])
        setBookingReturningBlockedTickets([])
        hideTimer()
      }).catch((error) => {
        console.error('Error desbloqueando tickets:', error)
        setLocalLoading(false)
      })
    } else {
      navigate(`${BASE_URL}/booking/search`)
    }
  }

  // Efectos
  useEffect(() => {
    if (bookingOrigin && bookingDestination) {
      searchForTrips(
        bookingOrigin,
        bookingDestination,
        bookingDepartureDate,
        bookingReturningDate,
        bookingTripConfig.redondo,
        bookingFares
      )
    }
  }, [bookingOrigin, bookingDestination])

  useEffect(() => {
    if (trips.length > 0) {
      organizeTripsIntoPages(trips)
    }
  }, [trips])

  useEffect(() => {
    if (bookingDepartureTrip || bookingReturningTrip) {
      navigate(`${BASE_URL}/booking/bus`)
    }
  }, [bookingDepartureTrip, bookingReturningTrip])

  return (
    <>
      <Loader show={loading || localLoading} />
      <section className={['section-full', 'section-flex'].join(' ')}>
        <div className={[global.top, styles.top].join(' ')}>
          <div className={['logo', bookingTripConfig.redondo ? 'roundAlign' : null, styles.logo].join(' ')}>
            <figure className={['logo-image', 'logo-image-transpais-light', bookingTripConfig.redondo ? 'logo-small' : null].join(' ')} />
            {bookingTripConfig.type === TripType.round && (
              <div className={styles.toPill}>
                <div className={styles.pill}>
                  <p>{bookingDepartureBlockedTickets.length === 0 ? 'Selecciona tu viaje de ida' : 'Selecciona tu viaje de regreso'}</p>
                </div>
              </div>
            )}
            <Timer variant={'clock'} />
          </div>

          <div className={styles.middle}>
            <div className={global.content}>
              <div className={styles.tripsHeader}>
                {/* <TripData variant={'brief'} data={{
                  origin: bookingOrigin,
                  destination: bookingDestination,
                  returning: bookingTripConfig.redondo,
                }} /> */}
                {/* <TripData variant={'meta'} meta={{ departure: bookingDepartureTrip, returning: bookingReturningTrip }} /> */}
              </div>

              <hr />

              {tripPages.length > 0 ? (
                <div className={styles.departures}>
                  <Carrousel width={1856}>
                    <div className={styles.pages}>
                      {tripPages.map((page, i) => (
                        <div key={`tp_${i}`} className={styles.departuresPage}>
                          {page.map((trip) => (
                            <Departure
                              key={`tpd_${trip.id}`}
                              onClick={() => handleSelectTrip(trip)}
                              variant={DepartureViewVariant.full}
                              data={trip}
                            />
                          ))}
                        </div>
                      ))}
                    </div>
                  </Carrousel>
                </div>
              ) : notFound ? (
                <div className={styles.departuresEmpty}>
                  <div className={styles.instructions}>
                    <div className={styles.retry} onClick={() => searchForTrips(bookingOrigin, bookingDestination, bookingDepartureDate, bookingReturningDate, bookingTripConfig.redondo)}>
                      Reintentar
                    </div>
                    <h4>¡Ops! No encontré viajes a tu destino. Puedes reintentar la búsqueda o cambiar la fecha.</h4>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <footer className={global.bottom}>
          <div>
            <Button onClick={handleBack} type="neutral" left-icon="chevron-left">Volver</Button>
          </div>
          <div className={global.middle}>
            <Button onClick={handleExit} type="positive" right-icon="house">Ir a inicio</Button>
          </div>
          <div>
            {/* <Button to={`${BASE_URL}/booking/bus`} type="positive" right-icon="chevron-right">Continuar</Button> */}
          </div>
        </footer>
      </section>
    </>
  )
}

export default BookingTrips