import React from 'react'
import styles from './TranspaisDeparture.module.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro'
import {currency} from './helpers/currency'
import { DepartureViewVariant, TripAmenity } from './enums'
import { formatInTimeZone } from 'date-fns-tz'
import { parseISO } from 'date-fns'

const TranspaisDeparture = ({data, variant, onClick}) => {
  const quick = () => (
    <div className={styles.departure}>
      <div className={styles.departureMeta}>
        <figure className={[styles.departureBrand].join(' ')}>
          <img src={ `/brands/ibus.svg` } alt="Brand"/>
          {/* <img src={data.brand} alt="Brand"/> */}
        </figure>
        <p className={styles.trip}>{data.from} <FontAwesomeIcon
          icon={solid('chevron-right')} size={'xs'}/> {data.to}</p>
      </div>

      <div className={styles.departureInfo}>
        <div>
          <h3>Salida</h3>
          <p>{data.departure}</p>
        </div>
        <div>
          <h3>Asientos</h3>
          <p>{data.manifestAvailability.available} <span>Libres</span></p>
        </div>
        <div>
          <h3 className={styles.right}>Precio</h3>
          <p><span className={styles.alt}>$</span>{currency(data.price/100000, false)}</p>
        </div>
      </div>
    </div>
  )

  const getFarePrice = () => {
    if (data.fareClasses && data.fareClasses.length > 0) {
      const fares = data.fareClasses[0].fares;
  
      const adultoFare = fares.find((fare) => fare.name === "Adulto");
  
      if (adultoFare) {
        return adultoFare.valueToDisplay;
      }
  
      const cheapestFare = fares.reduce((minFare, currentFare) => {
        return parseFloat(currentFare.valueToDisplay) < parseFloat(minFare.valueToDisplay)
          ? currentFare
          : minFare;
      });
  
      return cheapestFare.valueToDisplay;
    }
  
    return "N/A";
  };

  const full = () => {
    // console.log('data', data)
    var departure = data.departure.includes("-05:") ? data.departure.replace(/-05:/, "-06:") : data.departure
    var llegada_raw = data.arrival.includes("-05:") ? data.arrival.replace(/-05:/, "-06:") : data.arrival
    const salida = departure//parseISO(departure)
    const llegada = llegada_raw//parseISO(llegada_raw)

    return (
      <div onClick= { onClick } className={ [styles.departure, styles.departureFull].join(' ') }>
        <div className={ styles.data }>
          <div className={ styles.departureInfo }>
            <div>
              <div>
                <h3>Salida</h3>
                <p>{ salida }</p>
              </div>
              <div className={ [styles.departureExtra, styles.inner].join(' ') }>
                <h3>Asientos</h3>
                <p>{ data.manifestAvailability.available } libres</p>
              </div>
            </div>
            <div>
              <div>
                <h3>Llegada</h3>
                <p>{ llegada }</p>
              </div>
              <div className={ [styles.departureExtra, styles.inner].join(' ') }>
                <h3>Duración</h3>
                <p>{ data.duration }</p>
              </div>
            </div>
            <div className={ styles.withAmenities }>
              <div>
                <h3 className={ styles.right }>Precio</h3>
                <p>
                  <span className={styles.alt}>$</span>
                  {getFarePrice()}
                </p>
              </div>
              <div className={ styles.amenities }>
                {/* {data.amenidades.length > 0 && (
                  <>
                  {data.amenidades.map((amenity) => {
                    return <FontAwesomeIcon key={`am_${amenity.id}`} icon={ TripAmenity.icon(amenity.image) } className={ styles.icons } size={ '2xl' }/>
                  })}
                  </>
                )} */}
              </div>
            </div>
          </div>
        </div>
        <hr/>
        <div className={ [styles.departureMeta, styles.pad].join(' ') }>
          <figure className={ [styles.departureBrand].join(' ') }>
            {/* sacar de segments en dataTrip */}
          {/* <img src={ `/brands/ibus.svg` } alt="Brand"/> */}
            {/* <img src={ `/brands/${data.marca.replace(' ', '').toLowerCase()}.svg` } alt="Brand"/> */}
          </figure>
          {/*<a className={ styles.details }>Ver detalles</a>*/}
        </div>
      </div>
    )
  }

  const brief = () => (<div></div>)

  const renderView = () => {
    //console.debug('-------')
    //console.debug('variant', variant)
    switch (variant) {
      case DepartureViewVariant.quick:
        return quick()
      case DepartureViewVariant.brief:
        return brief()
      case DepartureViewVariant.full:
        return full()
      default:
        return full()
    }
  }

  return renderView()
}

export default TranspaisDeparture