import * as Sentry from '@sentry/react'
// Diccionario de traducciones (puede moverse a un archivo aparte si crece mucho)
const errorMessages = {
    'SEAT_TAKEN': 'El asiento seleccionado ya está ocupado',
    'INVALID_SEAT': 'Asiento no válido',
    'MAX_PASSENGERS': 'Has excedido el número máximo de pasajeros',
    'TIMEOUT': 'Tiempo de sesión agotado',
    'DEFAULT': 'Ocurrió un error al procesar tu solicitud'
}
  
// Función principal para procesar errores
export const handleApiError = (error, context = {}) => {
try {
    const errorResponse = error.response?.data?.message 
    ? JSON.parse(error.response.data.message)
    : error.message 
        ? { code: 'DEFAULT', message: error.message }
        : { code: 'DEFAULT', message: 'Error desconocido' }

    // Obtener mensaje traducido
    const userMessage = errorMessages[errorResponse.code] || errorMessages.DEFAULT

    // Preparar datos para logging
    const errorData = {
        code: errorResponse.code,
        originalMessage: errorResponse.message,
        userMessage,
        context,
        timestamp: new Date().toISOString(),
        stack: error.stack
    }

    return {
        userMessage,
        errorData // Para logging/debugging
    }
} catch (parseError) {
    return {
    userMessage: errorMessages.DEFAULT,
    errorData: {
        code: 'PARSE_ERROR',
        originalError: error,
        parseError,
        context
    }
    }
}
}
  
// Helper para mostrar alertas (abstrae la implementación UI)
export const showErrorAlert = (message, modalContext) => {
if (modalContext && modalContext.setupModal && modalContext.showModal) {
    modalContext.setupModal({
    title: 'Error',
    content: <p>{message}</p>,
    actions: {
        positive: {
        title: 'Entendido',
        onClick: () => modalContext.hideModal()
        }
    }
    })
    modalContext.showModal()
} else {
    alert(message)
}
}
  
// Función para reportar errores a Sentry
export const reportError = (error, metadata = {}) => {
    if (typeof Sentry !== 'undefined') {
        Sentry.captureException(error, {
        tags: { errorCode: metadata.code },
        extra: metadata
        })
    }
    console.error('Error reportado:', { error, metadata })
}